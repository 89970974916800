/******************************************************************
 * ErrorCodes
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class ErrorCode {

    static DUPLICATED_KEY_ERROR(key: string) {
        return "duplicated.key." + key
    }

    static readonly CORS_ORIGIN_NOT_ALLOWED = "cors.origin.not.allowed";

    static readonly NOT_AN_INTEGER = "not.an.integer";
    static readonly SETTING_KEY_INVALID = "setting.key.invalid";
    static readonly DICT_KEY_INVALID = "dict.key.invalid";
    static readonly DICT_ENTRY_NO_MISSING_TRANSLATIONS = "dictionary.entry.no.missing.translations";
    static readonly DICT_ENTRY_NO_BASE_TRANSLATION = "dictionary.entry.no.base.translation";
    static readonly UNKNOW_ERROR = "error.unknown";
    static readonly NICK_MISSING = "nick.missing";
    static readonly PASSWORD_MISSING = "password.missing";
    static readonly VERIFICATION_CODE_INVALID = "verification.code.invalid";
    static readonly SETTING_KEY_NOT_FOUND = "setting.key.not.found";
    static readonly SETTING_KEY_OR_VALUE_NOT_FOUND = "setting.key.or.value.not.found";
    static readonly CHANGELOG_NOT_FOUND = "changelog.not.found";
    static readonly NO_DEV_ENVIROMENT = "no.dev.enviroment";
    static readonly TRANSFER_DICTIONARY_INVALID_PARAMETER = "transfer.dictionary.invalid.parameter";
    static readonly DATABASE_CREATE_BACKUP_FAILED = "database.create.backup.failed";
    static readonly DATABASE_INSTALL_MONGO_TOOLS_FAILED = "database.install.mongo.tools.failed";
    static readonly FILE_NOT_FOUND = "file.not.found";
    static readonly DIRECTORY_NOT_FOUND = "directory.not.found";
    static readonly DATABASE_BACKUP_DIR_NOT_SET = "database.backup.dir.not.set";
    static readonly DATABASE_BACKUP_DELETE_FAILED = "database.backup.delete.failed";
    static readonly DATABASE_RESTORE_NO_PATH_PROVIDED = "database.restore.no.path.provided";
    static readonly DATABASE_RESTORE_FAILED = "database.restore.failed";
    static readonly CLI_SCRIPT_ERROR = "cli.script.error";
    static readonly LOG_TYPE_NOT_PROVIDED = "log.type.not.provided";
    static readonly FILE_PATH_NOT_PROVIDED = "file.path.not.provided";
    static readonly FILE_OWNER_INFO_MISSING = "file.owner.info.missing";
    static readonly LANGUAGE_INVALID = "language.invalid";
    static readonly MULTILANG_STRING_INVALID = "multilang.string.invalid";
    static readonly MULTILANG_OBJECTID_INVALID = "multilang.objectid.invalid";
    static readonly TRANSLATE_MISSING_PARAMETER = "translate.missing.parameter";

    static readonly CONTACT_NOT_AUTHORIZED = "contact.not.authorized";
    static readonly CONTACT_MISSING_PARAMETER = "contact.missing.parameter";
    static readonly CONTACT_MESSAGE_TOO_LONG = "contact.message.too.long";
    static readonly CONTACT_MESSAGE_TOO_SHORT = "contact.message.too.short";
    static readonly CONTACT_SEND_MESSAGE_FAILED = "contact.send.message.failed";

    static readonly ZIP_CODE_INVALID = "zip.code.invalid";
    static readonly COUNTRY_INVALID = "group.country.invalid";

    static readonly TEXT_INPUT_INVALID = "text.input.invalid";
    static readonly LINK_INPUT_INVALID = "link.input.invalid";
    static readonly COLOR_INPUT_INVALID = "color.input.invalid";

    static readonly PATH_NOT_AVAILABLE = "path.not.available";
    static readonly PATH_INVALID = "path.input.invalid";

    static readonly USER_ID_NOT_PROVIDED = "user.id.not.provided";
    static readonly USER_NOT_FOUND = "user.not.found";
    static readonly USER_MAIL_EXISTS = "user.mail.exists";
    static readonly USER_NICK_EXISTS = "user.nick.exists";
    static readonly USER_PASSWORD_INVALID = "user.password.invalid";
    static readonly USER_MAIL_INVALID = "user.mail.invalid";
    static readonly USER_PASSWORD_WRONG = "user.password.wrong";
    static readonly USER_VERIFICATION_CODE_WRONG = "user.code.wrong";
    static readonly USER_NICK_INVALID = "user.nick.invalid";
    static readonly USER_NAME_INVALID = "user.name.invalid";
    static readonly USER_STATE_INVALID = "user.state.invalid";
    static readonly USER_UPDATE_FORBIDDEN = "user.update.forbidden";
    static readonly USER_PATH_INVALID = "user.path.invalid";
    static readonly USER_SPEAKER_NICK_INVALID = "user.speaker.nick.invalid";
    static readonly USER_PERMISSION_INVALID = "user.permission.invalid";
    static readonly USER_ROLE_INVALID = "nick.role.invalid";
    static readonly USER_NEWSLETTER_STATE_INVALID = "user.newsletter.state.invalid";

    static readonly NO_AUTH_AVAILABLE = "auth.not.available";
    static readonly AUTH_TOKEN_INVALID = "auth.token.invalid";
    static readonly AUTH_MAIL_NOT_FOUND = "auth.mail.notfound";
    static readonly AUTH_NO_ADMIN = "auth.noadmin";
    static readonly AUTH_NO_PERMISSIONS = "auth.no.permissions";

    static readonly FILE_UPLOAD_FORBIDDEN = "file.upload.mimetype.forbidden"
    static readonly FILE_UPLOAD_NOFILE = "file.upload.nofile";
    static readonly FILE_UPLOAD_TO_LARGE = "LIMIT_FILE_SIZE";
    static readonly FILE_OWNER_TYPE_INVALID = "file.ownerType.invalid";

    static readonly DISCORD_WEBHOOK_INVALID = "discord.webhook.invalid";

    static readonly EVENT_FORBIDDEN = "event.forbidden";
    static readonly EVENT_UPDATE_FORBIDDEN = "event.update.forbidden";
    static readonly EVENT_NAME_INVALID = "event.name.invalid";
    static readonly EVENT_PRIVACY_INVALID = "event.privacy.invalid";
    static readonly EVENT_STATE_INVALID = "event.state.invalid";
    static readonly EVENT_NOT_FOUND = "event.not.found";
    static readonly EVENTS_NOT_FOUND = "events.not.found";
    static readonly EVENT_ACCESS_TYPE_INVALD = "event.access.type.invalid";
    static readonly EVENT_FOLDER_NAME_INVALID = "event.folder.name.invalid";
    static readonly EVENT_FOLDER_NOT_FOUND = "event.folder.not.found";
    static readonly EVENT_ORDER_INVALID = "event.children.order.invalid";
    static readonly EVENT_CREATE_MISSING_DATA = "event.create.missing.data";
    static readonly EVENT_OWNER_TYPE_INVALID = "event.ownerType.invalid";
    static readonly EVENT_PARTICIPATION_NOT_REQUIRED = "event.participation.not.required";
    static readonly EVENT_PARTICIPATION_ALREADY_REQUESTED = "event.participation.already.requested";
    static readonly EVENT_PARTICIPATION_ALREADY_REGISTERED = "event.already.registered";
    static readonly EVENT_PARTICIPATION_REQUEST_PENDING = "event.participation.request.pending";
    static readonly EVENT_PARTICIPATION_REQUEST_FORBIDDEN = "event.participation.request.forbidden";
    static readonly EVENT_VALIDATION_FOLLOW_SESSION_START_TIME_DELAY_INVALID = "error.validation.follow.session.start.time.delay.invalid";
    static readonly EVENT_VALIDATION_FOLLOW_SESSION_GAME_ID_RULE_TYPE_INVALID = "error.validation.follow.session.game.id.rule.type.invalid";
    static readonly EVENT_CONTACT_NOT_FOUND = "event.contact.not.found";

    static readonly SESSION_CREATE_MISSING_DATA = "session.create.missing.data"
    static readonly SESSION_NOT_FOUND = "session.not.found";
    static readonly SESSION_STATE_INVALID = "session.state.invalid";
    static readonly SESSION_FORBIDDEN = "session.forbidden";
    static readonly SESSION_NOT_ACTIVE = "session.not.active";
    static readonly SESSION_UPDATE_FORBIDDEN = "session.update.forbidden";
    static readonly SESSION_PATH_ALREADY_IN_USE = "session.path.already.used";
    static readonly SESSION_LAPS_INVALID = "session.laps.invalid";
    static readonly SESSION_MODE_INVALID = "session.mode.invalid";
    static readonly SESSION_CLASSIFICATION_MODE_INVALID = "session.classification.invalid";
    static readonly SESSION_DURATION_INVALID = "session.duration.invalid";
    static readonly SESSION_FINISH_TYPE_INVALID = "session.finishType.invalid";
    static readonly SESSION_TIMING_INVALID = "session.timing.invalid";
    static readonly SESSION_STINTS_INVALID = "session.stints.invalid";
    static readonly SESSION_SETUP_SECTION_INVALID = "session.setup.section.invalid";
    static readonly SESSION_SECTOR_TARGET_INVALID = "session.sector.target.invalid";
    static readonly SESSION_JOKER_TARGET_INVALID = "session.joker.target.invalid";
    static readonly SESSION_POINTS_INVALID = "session.points.invalid";
    static readonly SESSION_PENALITY_TIME_INVALID = "session.penalty.time.invalid";
    static readonly SESSION_STINT_VALIDATION_MODE_INVALID = "session.stint.validation.mode.invalid";
    static readonly SESSION_DRIVER_STATE_INVALID = "session.driver.state.invalid";
    static readonly SESSION_LEADERBOARD_GROUPING_INVALID = "session.leaderboard.grouping.invalid";
    static readonly SESSION_TEAM_SCORING_MODE_INVALID = "session.team.scoring.mode.invalid";
    static readonly SESSION_SIMULTANEOUS_TEAM_DRIVERS_INVALID = "session.simultaneous.team.drivers.invalid"
    static readonly SESSION_SYNCED_LAP_RACE_FULFILL_MODE_INVALID = "session.syncedLapRace.fulfillMode.invalid";
    static readonly SESSION_NAME_INVALID = "session.name.invalid";
    static readonly SESSION_ID_NOT_PROVIDED = "session.id.not.provided";
    static readonly SESSION_TARGET_PREPROCESSOR_INVALID = "session.target.preprocessor.invalid";
    static readonly INVALID_APPROVAL_STATE_TYPE = "approval.state.type.invalid";

    static readonly RESULT_CREATE_MISSING_DATA = "result.create.missing.data";
    static readonly RESULT_NOT_FOUND = "result.not.found";
    static readonly RESULT_NAME_INVALID = "result.name.invalid";
    static readonly RESULT_PATH_ALREADY_IN_USE = "result.path.already.in.use";
    static readonly RESULT_UPDATE_FORBIDDEN = "result.update.forbidden";

    static readonly LEADERBOARD_NOT_FOUND = "leaderboard.not.found";

    static readonly STINT_NOT_FOUND = "stint.not.found";
    static readonly STINTS_NOT_FOUND = "stints.not.found";
    static readonly STINT_UPDATE_FORBIDDEN = "stint.update.forbidden";
    static readonly STINT_DELETE_FORBIDDEN = "stint.delete.forbidden";

    static readonly TRACK_NAME_INVALID = "track.name.invalid";
    static readonly TRACK_LAYOUT_INVALID = "track.layout.invalid";
    static readonly TRACK_CREATE_MISSING_DATA = "track.create.missing.data";
    static readonly TRACK_NOT_FOUND = "track.not.found";
    static readonly TRACK_FORBIDDEN = "track.forbidden";
    static readonly TRACK_UPDATE_FORBIDDEN = "track.update.forbidden";
    static readonly TRACK_PATH_ALREADY_IN_USE = "track.path.already.used";
    static readonly TRACK_OWNER_TYPE_INVALID = "track.ownerType.invalid";
    static readonly TRACK_FILTER_NAME_INVALID = "track.filter.name.invalid";
    static readonly TRACK_MINLAPTIME_INVALID = "track.minLapTime.invalid";
    static readonly TRACK_TYPE_INVALID = "track.type.invalid";
    static readonly TRACK_NUM_SECTORS_INVALID = "track.num.sectors.invalid";
    static readonly TRACK_LAP_COUNT_MODE_INVALID = "track.lap.count.mode.invalid";

    static readonly DRIFT_TIRES_INVALID = "drift.tires.invalid";
    static readonly DRIFT_TRACK_BUNDLE_INVALID = "drift.trackbundle.invalid";
    static readonly DRIFT_ENGINE_INVALID = "drift.engine.invalid";
    static readonly DRIFT_TUNING_INVALID = "drift.tuning.invalid";
    static readonly DRIFT_TRACKCONDITION_INVALID = "drift.trackcondition.invalid";
    static readonly DRIFT_CARSETUP_INVALID = "drift.carsetup.invalid";
    static readonly DRIFT_GAME_MODE_INVALID = "drift.game.mode.invalid";

    static readonly GROUP_NOT_FOUND = "group.not.found";
    static readonly GROUP_UPDATE_FORBIDDEN = "group.update.forbidden";
    static readonly GROUP_NAME_INVALID = "group.name.invalid";
    static readonly GROUP_MEMBER_ROLE_INVALID = "group.member.role.invalid";
    static readonly GROUPS_NOT_FOUND = "groups.not.found";
    static readonly GROUP_PATH_ALREADY_IN_USE = "group.path.already.used";
    static readonly GROUP_LOCATION_INVALID = "group.location.invalid";
    static readonly GROUP_TYPE_INVALID = "group.type.invalid";
    static readonly GROUP_NOT_ALLOWED = "group.not.allowed";

    static readonly GARAGE_NOT_FOUND = "garage.not.found";

    static readonly CAR_STATE_INVALID = "car.state.invalid";
    static readonly CAR_NOT_FOUND = "car.not.found";
    static readonly CAR_CREATE_MISSING_DATA = "car.create.missing.data";
    static readonly CAR_NAME_INVALID = "car.name.invalid";
    static readonly CAR_CHASSIS_INVALID = "car.chassis.invalid";
    static readonly CAR_PROPERTY_TYPE_INVALID = "car.property.type.invalid";
    static readonly CAR_PROPERTY_TEXT_INVALID = "car.property.text.invalid";

    static readonly CAPI_REQUEST_GAMEMODE_INVALID = "capi.request.gamemode.invalid";
    static readonly CAPI_REQUEST_USER_NOT_FOUND_OR_PASSWORD_WRONG = "capi.request.user.not.found.or.password.wrong";
    static readonly CAPI_REQUEST_USER_NO_PASSWORD = "capi.request.user.no.password";
    static readonly CAPI_REQUEST_USER_FORBIDDEN = "capi.request.user.forbidden";
    static readonly CAPI_REQUEST_PAYLOAD_MISSING = "capi.request.payload.missing";
    static readonly CAPI_REQUEST_STINT_CREATE_FAILED = "capi.request.stint.create.failed";
    static readonly CAPI_REQUEST_REENTER_FORBIDDEN = "capi.request.stint.reenter.forbidden";
    static readonly CAPI_REQUEST_DRIVER_IS_DRIVING = "capi.request.driver.is.driving";
    static readonly CAPI_REQUEST_USERAGENT_INVALID = "capi.request.useragent.invalid";
    static readonly CAPI_REQUEST_ROUTE_PARSE_FAILED = "capi.request.route.parse.failed";
    static readonly CAPI_REQUEST_STINT_MISSING = "capi.request.stint.missing";
    static readonly CAPI_REQUEST_SESSION_NOT_RUNNING = "capi.request.session.not.running";
    static readonly CAPI_REQUEST_STINT_NOT_STARTED = "capi.request.stint.not.started"
    static readonly CAPI_REQUEST_STINT_NOT_READY = "capi.request.stint.not.ready"
    static readonly CAPI_REQUEST_TARGET_CROSSINGTIME_ALREADY_EXISTS = "capi.request.target.crossingTime.already.exists";
    static readonly CAPI_REQUEST_SESSION_FULLFILLED = "capi.request.session.fullfilled";
    static readonly CAPI_REQUEST_TEAM_DRIVER_FORBIDDEN = "capi.request.team.driver.forbidden";
    static readonly CAPI_REQUEST_TEAM_FORBIDDEN = "capi.request.team.forbidden";
    static readonly CAPI_REQUEST_TARGET_PROCESS_ERROR = "capi.request.target.process.error";
    static readonly CAPI_REQUEST_DRIVER_LICENSE_NOT_ALLOWED = "capi.request.driver.license.not.allowed";
    static readonly CAPI_REQUEST_USER_NOT_VERIFIED = "capi.request.user.not.verified";

    static readonly YOUTUBE_URL_INVALID = "youtube.url.invalid";
    static readonly YOUTUBE_ID_INVALID = "youtube.id.invalid";

    static readonly ACADEMY_CHAPTER_ID_NOT_SET = "academy.chapter.id.not.set";
    static readonly ACADEMY_CHAPTERS_NOT_FOUND = "academy.chapters.not.found";
    static readonly ACADEMY_CHAPTER_COULD_NOT_BE_CREATED = "academy.chapter.could.not.be.created";
    static readonly ACADEMY_CHAPTER_COULD_NOT_BE_UPDATED = "academy.chapter.could.not.be.updated";
    static readonly ACADEMY_CHAPTER_COULD_NOT_BE_DELETED = "academy.chapter.could.not.be.deleted";
    static readonly ACADEMY_CHAPTER_TITLE_INVALID = "academy.chapter.title.invalid";
    static readonly ACADEMY_CHAPTER_NOT_FOUND = "academy.chapter.not.found";

    static readonly MAIL_ID_MISSING = "mail.id.missing";
    static readonly MAIL_RECIPIENT_ID_MISSING = "mail.recipient.id.missing";
    static readonly MAIL_NOT_FOUND = "mail.not.found";
    static readonly MAILS_NOT_FOUND = "mails.not.found";
    static readonly MAIL_COULD_NOT_BE_CREATED = "mail.could.not.be.created";
    static readonly MAIL_COULD_NOT_BE_UPDATED = "mail.could.not.be.updated";
    static readonly MAIL_COULD_NOT_BE_DELETED = "mail.could.not.be.deleted";
    static readonly MAIL_COULD_NOT_BE_SENT = "mail.could.not.be.sent";
    static readonly MAIL_SEND_NOT_ALLOWED = "mail.send.not.allowed";
    static readonly MAIL_CREATE_NOT_ALLOWED = "mail.create.not.allowed";
    static readonly MAIL_UPDATE_NOT_ALLOWED = "mail.update.not.allowed";
    static readonly MAIL_DELETE_NOT_ALLOWED = "mail.delete.not.allowed";
    static readonly MAIL_RECIPIENTS_FILTER_INVALID = "mail.recipients.filter.invalid";
    static readonly MAIL_TEMPLATE_TYPE_INVALID = "mail.template.type.invalid";
    static readonly MAIL_STATE_INVALID = "mail.state.invalid";
    static readonly MAIL_INVALID = "mail.invalid";
    static readonly MAIL_MISSING = "mail.missing";
    static readonly MAIL_SEND_FAILED = "mail.send.failed";
    static readonly MAIL_SERVICE_NOT_CONFIGURED = "mail.service.not.configured";
    static readonly MAIL_NOT_ALLOWED = "mail.not.allowed";
    static readonly MAIL_NO_RECIPIENTS = "mail.no.recipients";
    static readonly MAIL_IS_ALREADY_SENDING = "mail.is.already.sending";
    static readonly MAIL_SUBJECT_MISSING = "mail.subject.missing";
    static readonly MAIL_BODY_MISSING = "mail.body.missing";
    static readonly MAIL_ALREADY_WHITELISTED = "mail.already.whitelisted";
    static readonly MAIL_LANGUAGE_TYPE_INVALID = "mail.language.type.invalid";

    static readonly ARTICLE_LANGUAGE_MISSING = "article.language.missing";
    static readonly ARTICLE_TITLE_MISSING = "article.title.missing";
    static readonly ARTICLE_COULD_NOT_BE_CREATED = "article.could.not.be.created";
    static readonly ARTICLE_COULD_NOT_BE_UPDATED = "article.could.not.be.updated";
    static readonly ARTICLE_ID_MISSING = "article.id.missing";
    static readonly ARTICLE_COULD_NOT_BE_DELETED = "article.could.not.be.deleted";
    static readonly ARTICLE_NOT_FOUND = "article.not.found";
    static readonly ARTICLES_NOT_FOUND = "articles.not.found";
    static readonly ARTICLE_COULD_NOT_BE_COPIED = "article.could.not.be.copied";
    static readonly ARTICLE_CREATE_NOT_ALLOWED = "article.create.not.allowed";
    static readonly ARTICLE_UPDATE_NOT_ALLOWED = "article.update.not.allowed";
    static readonly ARTICLE_DELETE_NOT_ALLOWED = "article.delete.not.allowed";
    static readonly ARTICLE_TYPE_INVALID = "article.type.invalid";

    static readonly PRESET_INIT_CATEGORY_FAILED = "preset.init.category.failed";
    static readonly PRESET_ADD_SESSION_PRESET_FAILED = "preset.add.session.preset.failed";
    static readonly PRESET_GET_SESSION_PRESETS_FAILED = "preset.get.session.presets.failed";
    static readonly PRESET_NOT_FOUND_OR_NOT_UPDATED = "preset.not.found.or.not.updated";
    static readonly PRESET_UPDATE_SESSION_PRESET_FAILED = "preset.update.session.preset.failed";
    static readonly PRESET_UPDATE_SESSION_PRESET_MISSING_DATA = "preset.update.session.preset.missing.data";
    static readonly PRESET_DELETE_SESSION_PRESET_FAILED = "preset.delete.session.preset.failed";
    static readonly PRESET_DELETE_SESSION_PRESET_MISSING_DATA = "preset.delete.session.preset.missing.data";
    static readonly PRESET_ADD_SESSION_PRESET_MISSING_DATA = "preset.add.session.preset.missing.data";
    static readonly PRESET_GET_SESSION_PRESETS_MISSING_DATA = "preset.get.session.presets.missing.data";
    static readonly PRESET_ADD_SESSION_PRESET_DUPLICATE_NAME = "preset.add.session.preset.duplicate.name";

    static readonly DRIVER_LICENSE_INVALID = "driver.license.invalid";

    static readonly PADDLE_CUSTOMER_NOT_FOUND = "paddle.customer.not.found";
    static readonly PADDLE_SUBSCRIPTION_NOT_FOUND = "paddle.subscription.not.found";
    static readonly PADDLE_CUSTOMER_NOT_CREATED = "paddle.customer.not.created";
    static readonly PADDLE_MISSING_SUBSCRIPTION_DATA = "paddle.missing.subscription.data";
    static readonly PADDLE_NOT_AVAILABLE = "paddle.not.available";
    static readonly PADDLE_SUBSCRIPTION_STATE_INVALID = "paddle.subscription.state.invalid";
    static readonly PADDLE_CHECKOUT_STATE_INVALID = "paddle.checkout.state.invalid";

    static readonly PLAN_ACCESS_MAX_CARS_REACHED = "plan.access.max.cars.reached";
    static readonly PLAN_ACCESS_MAX_TRACKS_REACHED = "plan.access.max.tracks.reached";
    static readonly PLAN_ACCESS_TRACK_NOT_AVAILABLE = "plan.access.track.not.available";
    static readonly PLAN_ACCESS_NO_GROUP_CREATION_ALLOWED = "plan.access.no.group.creation.allowed";
    static readonly PLAN_ACCESS_NO_EVENT_CREATION_ALLOWED = "plan.access.no.event.creation.allowed";
    static readonly PLAN_ACCESS_NO_EVENT_RESULT_CREATION_ALLOWED = "plan.access.no.event.result.creation.allowed";
    static readonly PLAN_ACCESS_NO_SESSION_PRESET_CREATION_ALLOWED = "plan.access.no.session.preset.creation.allowed";

}

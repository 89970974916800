import * as React from "react";
import {CheckIcon} from "../../icons/CheckIcon";
import {IconType} from "../../icons/IconType";
import {Icon, IconColorType} from "../../icons/Icon";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {DriverLicenseBadge} from "../../info/license/DriverLicenseBadge";
import {ReactElement} from "react";

/******************************************************************
 * ContextMenuSelectable
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ContextMenuSelectable(props: {
    icon?: IconType
    iconColor?: IconColorType
    label: string
    onClick: (needHigherDriverLicense: boolean) => void
    stopPropagation?: boolean
    selected: boolean
    badgeElement?: ReactElement
    requiredDriverLicense?: DriverLicenseType
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {authUserValidDriverLicense, authUserRole} = useAuthUser()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function onClick(e: React.MouseEvent<HTMLDivElement>) {
        if (props.stopPropagation) {
            e.stopPropagation()
        }
        props.onClick(needHigherLicenseType())
    }

    function needHigherLicenseType() {
        return DriverLicenseUtils.needHigherLicenseType(
            authUserValidDriverLicense,
            props.requiredDriverLicense,
            authUserRole
        )
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="context-menu-selectable"
            onClick={onClick}
            data-has-icon={!!props.icon}
            data-has-badge={!!props.badgeElement}
            data-selected={props.selected ? "true" : "false"}>
            <div className="context-menu-selectable-label-with-check">
                {needHigherLicenseType()
                    ? <DriverLicenseBadge licenseType={props.requiredDriverLicense}/>
                    : <CheckIcon/>}
                <div>{props.label}</div>
                {props.badgeElement &&
                    <div className="context-menu-selectable-badge">
                        {props.badgeElement}
                    </div>}
            </div>
            {props.icon &&
                <Icon
                    type={props.icon}
                    scale={0.8}
                    iconColor={props.iconColor}
                />}
        </div>
    );

}

import * as React from "react";
import {useEffect} from "react";
import {useServices} from "../../../../hooks/useServices";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Table} from "../../../../ui/table/Table";
import {IMailData} from "../../../../../../shared/models/IMailData";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {ITableColumnDefinition} from "../../../../ui/table/ITableColumnDefinition";
import {ActionBarSpacer} from "../../../../ui/bar/spacer/ActionBarSpacer";
import {MailServiceSelector} from "../../../../ui/form/elements/mail/MailServiceSelector";
import {Divider} from "../../../../ui/utils/divider/Divider";
import {useEnviroment} from "../../../../hooks/useEnviroment";

/******************************************************************
 * AdminMailTemplates
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AdminMailTemplates() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, api} = useServices()
    const {isDevEnviroment} = useEnviroment()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        loadMails()
        state.showMail.onChangeSignal.add(loadMails)
        return () => state.showMail.onChangeSignal.remove(loadMails)
    }, [])

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [mails, setMails] = React.useState<IMailData[]>([])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    async function loadMails() {
        const mails = await api.mail.getAllMailTemplates()
        setMails(mails)
    }

    function columnsDefinition(): ITableColumnDefinition<IMailData>[] {
        return [{
            type: "text-bold",
            sortKey: "subject",
            columnName: dict("mail.table.column.subject"),
            size: "3fr",
            value: data => data.subject ?? dict("mail.table.subject.empty"),
        }, {
            type: "text",
            columnName: dict("mail.table.column.template.type"),
            size: "3fr",
            value: data => dict(`mail.template.type.${data.templateType}`)
        }, {
            type: "text",
            sortKey: "language",
            columnName: dict("mail.table.column.language"),
            size: "100px",
            value: data => dict(`language.${data.language}`)
        }]
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="admin-mail-templates">
            <Table<IMailData>
                rowsData={mails}
                columnsDefinition={columnsDefinition()}
                showHeader={true}
                showFilter={true}
                onRowClick={data => state.showMail.setValue(data._id)}/>
            <ActionBar location="main">
                {isDevEnviroment && <>
                    <ActionBarSpacer/>
                    <MailServiceSelector/>
                    <Divider style="action-bar"/>
                </>}
                <LabelButton
                    icon="plus"
                    label={dict("add.mail.template.label")}
                    style="action-main"
                    onClick={() => state.showMail.setValue(true)}/>
            </ActionBar>
        </ContentLayout>
    );

}

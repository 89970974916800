import * as React from "react";
import {ReactElement, useEffect, useState} from "react";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {useRouteOwner} from "../../../hooks/useRouteOwner";
import {EventInfo} from "./info/EventInfo";
import {useGroupState} from "../../../hooks/useGroupState";
import {ActionBar} from "../../../ui/bar/ActionBar";
import {IconButton} from "../../../ui/buttons/icon/IconButton";
import {EventTeaser} from "../../../ui/teaser/event/EventTeaser";
import {useEventState} from "../../../hooks/useEventState";
import {useDeviceInfo} from "../../../hooks/useDeviceInfo";
import {Divider} from "../../../ui/utils/divider/Divider";
import {ActionBarSpacer} from "../../../ui/bar/spacer/ActionBarSpacer";
import {EventStateSelector} from "../../../ui/context/state/EventStateSelector";
import {LabelButton} from "../../../ui/buttons/label/LabelButton";
import {PrivacySelector} from "../../../ui/context/privacy/PrivacySelector";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {EventUtils} from "../../../../../shared/utils/EventUtils";
import {InfoText} from "../../../ui/text/infotext/InfoText";
import {ArticleReaderBox} from "../../../ui/article/reader/ArticleReaderBox";

/******************************************************************
 * EventPage
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function EventPage() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict} = useServices()
    const {authUserID, authUserNick} = useAuthUser()
    const {isRouteOwnerAuthUser} = useRouteOwner()
    const {isGroupAdmin} = useGroupState()
    const {isMobile} = useDeviceInfo()
    const {
        eventData,
        eventState,
        eventName,
        eventIsFinished,
        eventOwnerType,
        eventAboutArticle,
        eventRegisteredMessage,
        eventEntryFeeMessage
    } = useEventState()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showParticipationRequestInfo, setShowParticipationRequestInfo] = useState<boolean>(false)
    const [isTraining] = useState(eventOwnerType === "user")

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        setShowParticipationRequestInfo(computeShowParticipationRequestInfo())
    }, [eventData])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function isEvent() {
        return eventOwnerType == "group";
    }

    function showAdminActionBar() {
        return isGroupAdmin || isRouteOwnerAuthUser
    }

    function showContactActionBar() {
        return !showAdminActionBar() && !eventIsFinished && !isTraining
    }

    function computeShowParticipationRequestInfo(): boolean {
        if (eventState == "finished") {
            return false
        }
        if (!EventUtils.needParticipationRequest(eventData)) {
            return false
        }
        if (EventUtils.isUserAlreadyRegistered(eventData, authUserID)) {
            return true
        }
        if (EventUtils.isParticipationRequestPending(eventData, authUserID)) {
            return true
        }
        if (EventUtils.hasUserAlreadyRequestedParticipation(eventData, authUserID)) {
            return true
        }
        return false
    }

    function AdminActionBar(): ReactElement {
        return (
            <ActionBar location="main">
                {(isMobile || eventState == "finished") && <ActionBarSpacer/>}
                {isMobile && eventState != "finished" && <>
                    <IconButton
                        type="add"
                        size="small"
                        opacityMode="none"
                        onClick={() => state.showCreateSession.setValue(true)}/>
                    <Divider style="action-bar"/>
                </>}
                {!isMobile && eventState != "finished" && <>
                    <LabelButton
                        label={dict("event.add.session.label")}
                        style="action-main"
                        icon="plus"
                        onClick={() => state.showCreateSession.setValue(true)}/>
                    <Divider style="action-bar"/>
                </>}
                <IconButton
                    type="settings"
                    size="small"
                    scale={0.9}
                    onClick={() => state.showEventSettings.setValue(true)}/>
                <Divider style="action-bar"/>
                <PrivacySelector/>
                {isEvent() && <>
                    <Divider style="action-bar"/>
                    <EventStateSelector/>
                </>}
                <Divider style="action-bar"/>
                <IconButton
                    type="academy"
                    size="small"
                    onClick={() => state.showAcademy.setValue(dict("academy.id.events"))}/>
                <ActionBarSpacer/>
            </ActionBar>
        )
    }

    function participationInfo(): string {
        if (EventUtils.isUserAlreadyRegistered(eventData, authUserID)) {
            return dict("event.participation.registered.state.info")
        }
        if (EventUtils.isParticipationRequestPending(eventData, authUserID)) {
            return dict("event.participation.pending.state.info")
        }
        return dict("event.participation.request.state.info")
    }

    function showParticipationDetails() {
        if (EventUtils.isUserAlreadyRegistered(eventData, authUserID)) {
            state.showMessage.setValue({
                type: "long",
                message: parseEventMessage(eventRegisteredMessage
                    ?? dict("event.driver.registered.mail.text"))
            })
            return
        }
        if (EventUtils.isParticipationRequestPending(eventData, authUserID)) {
            state.showMessage.setValue({
                type: "long",
                message: parseEventMessage(eventEntryFeeMessage
                    ?? dict("event.driver.pending.mail.text"))
            })
            return
        }
    }

    function isRequestInfoClickable(): boolean {
        return EventUtils.isUserAlreadyRegistered(eventData, authUserID)
            || EventUtils.isParticipationRequestPending(eventData, authUserID)
    }

    function parseEventMessage(message: string): string {
        return message
            ?.replace(/{NICK}/g, authUserNick)
            ?.replace(/{EVENT_NAME}/g, "»" + eventName + "«")
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="event-page" alignSelf="start">
            <EventTeaser event={eventData} usedAsEventHeader={true}/>
            {showParticipationRequestInfo &&
                <ContentLayout justifyItems="center">
                    <InfoText
                        style="warning"
                        size="small"
                        align="center"
                        showClickInfo={isRequestInfoClickable()}
                        text={participationInfo()}
                        onClick={showParticipationDetails}/>
                </ContentLayout>}
            {eventAboutArticle &&
                <ArticleReaderBox
                    type="event"
                    articleID={eventAboutArticle._id ?? eventAboutArticle}/>}
            <EventInfo/>
            {showAdminActionBar() &&
                <AdminActionBar/>}
            {showContactActionBar() &&
                <ActionBar location="main">
                    <LabelButton
                        label={dict("event.sendMessage.button.label")}
                        style="action-main"
                        onClick={() => state.showContact.setValue({
                            sendMessageTo: eventData,
                            messageType: "event"
                        })}/>
                </ActionBar>}
        </ContentLayout>
    );

}

import * as React from "react";
import {useEffect, useState} from "react";
import {Modal} from "../Modal";
import {Headline} from "../../../ui/text/headings/Headline";
import {useServices} from "../../../hooks/useServices";
import {ContentLayout} from "../../../ui/layout/content/ContentLayout";
import {IUserData} from "../../../../../shared/models/IUserData";
import {UserInfo} from "../../../ui/info/user/UserInfo";
import {SelectInput, SelectInputOptionType} from "../../../ui/form/elements/select/SelectInput";
import {USER_ROLES, UserRoleType} from "../../../../../shared/types/UserRoleType";

/******************************************************************
 * UserRolesModal
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UserRolesModal() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();

    /* ----------------------------------------------------------------
     * STATES
     * --------------------------------------------------------------*/

    const [user, setUser] = useState<IUserData>(state.showUserRoles.getValue());

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        requestUserData()
    }, [])

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function userRolesOptions(): SelectInputOptionType[] {
        return USER_ROLES
            .filter(role => role !== "admin")
            .map(role => {
            return {
                value: role,
                text: dict("user.role." + role)
            }
        })
    }

    async function updateRole(role: UserRoleType) {
        await api.user.update({
            _id: user._id,
            role: role
        })
        await requestUserData()
    }

    async function requestUserData() {
        const userData = await api.user.getUserDataByID(user._id)
        setUser(userData)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Modal
            className="user-roles-modal"
            width="medium"
            closeAction={() => state.showUserRoles.setValue(null)}>
            <ContentLayout>
                <ContentLayout gap="none">
                    <Headline text={dict("modal.user.role.title")} style="modal"/>
                    <UserInfo user={user} clickToShowProfile={false} style="avatar"/>
                </ContentLayout>
                <SelectInput
                    options={userRolesOptions()}
                    defaultValue={user.role}
                    onChange={updateRole}/>
            </ContentLayout>
        </Modal>
    );

}

import * as React from "react";
import {useRef} from "react";
import {IconType} from "./IconType";
import {UserIcon} from "./UserIcon";
import {LogoutIcon} from "./LogoutIcon";
import {SettingsIcon} from "./SettingsIcon";
import {CloseIcon} from "./CloseIcon";
import {DeleteIcon} from "./DeleteIcon";
import {CancelEditIcon} from "./CancelEditIcon";
import {SaveEditIcon} from "./SaveEditIcon";
import {PublicIcon} from "./PublicIcon";
import {PrivateIcon} from "./PrivateIcon";
import {MenuDotsIcon} from "./MenuDotsIcon";
import {CheckIcon} from "./CheckIcon";
import {DateIcon} from "./DateIcon";
import {InfoIcon} from "./InfoIcon";
import {AddIcon} from "./AddIcon";
import {ChartIcon} from "./ChartIcon";
import {FolderIcon} from "./FolderIcon";
import {FlagIcon} from "./FlagIcon";
import {ArrowIcon} from "./ArrowIcon";
import {EditIcon} from "./EditIcon";
import {PlusIcon} from "./PlusIcon";
import {FilterIcon} from "./FilterIcon";
import {BlueprintIcon} from "./BlueprintIcon";
import {HamburgerIcon} from "./HamburgerIcon";
import {LeaveIcon} from "./LeaveIcon";
import {NextIcon} from "./NextIcon";
import {PrevIcon} from "./PrevIcon";
import {UpIcon} from "./UpIcon";
import {DownIcon} from "./DownIcon";
import {SyncIcon} from "./SyncIcon";
import {StarIcon} from "./StarIcon";
import {SumIcon} from "./SumIcon";
import {ToolIcon} from "./ToolIcon";
import {LinkIcon} from "./LinkIcon";
import {TitleIcon} from "./TitleIcon";
import {WaveIcon} from "./WaveIcon";
import {AvgIcon} from "./AvgIcon";
import {TrophyIcon} from "./TrophyIcon";
import {WatchIcon} from "./WatchIcon";
import {DriftIcon} from "./DriftIcon";
import {WarningIcon} from "./WarningIcon";
import {JokerIcon} from "./JokerIcon";
import {YouTubeIcon} from "./YouTubeIcon";
import {SortableIcon} from "./SortableIcon";
import {ThreeSixtyDriftTargetIcon} from "./ThreeSixtyDriftTargetIcon";
import {OneEightyDriftTargetIcon} from "./OneEightyDriftTargetIcon";
import {SpeedDriftTargetIcon} from "./SpeedDriftTargetIcon";
import {FinishTargetIcon} from "./FinishTargetIcon";
import {AngleDriftTargetIcon} from "./AngleDriftTargetIcon";
import {SpeakerIcon} from "./SpeakerIcon";
import {SpeakerDisbaledIcon} from "./SpeakerDisbaledIcon";
import {HelpIcon} from "./HelpIcon";
import {RestoreIcon} from "./RestoreIcon";
import {CopyIcon} from "./CopyIcon";
import {SidebarIcon} from "./SidebarIcon";
import {LocationIcon} from "./LocationIcon";
import {OnlineIcon} from "./OnlineIcon";
import {ClipboardIcon} from "./ClipboardIcon";
import {ClipboardCheckIcon} from "./ClipboardCheckIcon";
import {AcademyIcon} from "./AcademyIcon";
import {TextIcon} from "./TextIcon";
import {GalleryIcon} from "./GalleryIcon";
import {ImageIcon} from "./ImageIcon";
import {DownloadIcon} from "./DownloadIcon";
import {UploadIcon} from "./UploadIcon";
import {ZoomIcon} from "./ZoomIcon";
import {EmptyStarIcon} from "./EmptyStarIcon";
import {PersonIcon} from "./PersonIcon";
import {ZoomOutIcon} from "./ZoomOutIcon";
import {HiddenIcon} from "./HiddenIcon";
import {VisibleIcon} from "./VisibleIcon";
import {ProDriverIcon} from "./ProDriverIcon";
import {ClubDriverIcon} from "./ClubDriverIcon";
import {TestDriverTinyIcon} from "./TestDriverTinyIcon";
import {ProDriverTinyIcon} from "./ProDriverTinyIcon";
import {ClubDriverTinyIcon} from "./ClubDriverTinyIcon";
import {TestDriverIcon} from "./TestDriverIcon";
import {TranslateIcon} from "./TranslateIcon";
import {ShiftIcon} from "./ShiftIcon";
import {SearchIcon} from "./SearchIcon";
import {PeopleIcon} from "./PeopleIcon";
import {LiveIcon} from "./LiveIcon";
import {MegaphoneIcon} from "./MegaphoneIcon";
import {Tooltip} from "../tooltip/Tooltip";
import {MailIcon} from "./MailIcon";
import {PendingIcon} from "./PendingIcon";
import {JudgeUserIcon} from "./JudgeUserIcon";
import {JudgeUserTinyIcon} from "./JudgeUserTinyIcon";

/******************************************************************
 * Icon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export type IconColorType = "red" | "yellow" | "green" | "white" | "black"

export function Icon(props: {
    type: IconType,
    className?: string,
    iconColor?: IconColorType,
    stateIconType?: IconType,
    stateIconColor?: IconColorType,
    stateIconScale?: number,
    scale?: number
    warning?: boolean
    tooltip?: string,
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    function getIcon(iconType: IconType, scale: number = 1): JSX.Element {
        switch (iconType) {
            case "user":
                return <UserIcon scale={scale}/>
            case "logout":
                return <LogoutIcon/>
            case "settings":
                return <SettingsIcon scale={scale}/>
            case "close":
                return <CloseIcon scale={scale}/>
            case "delete":
                return <DeleteIcon/>
            case "cancel":
                return <CancelEditIcon scale={scale}/>
            case "save":
                return <SaveEditIcon scale={scale}/>
            case "public":
                return <PublicIcon scale={scale}/>
            case "private":
                return <PrivateIcon scale={scale}/>
            case "menuDots":
                return <MenuDotsIcon/>
            case "check":
                return <CheckIcon scale={scale}/>
            case "date":
                return <DateIcon scale={scale}/>
            case "info":
                return <InfoIcon scale={scale}/>
            case "add":
                return <AddIcon scale={scale}/>
            case "chart":
                return <ChartIcon scale={scale}/>
            case "folder":
                return <FolderIcon scale={scale}/>
            case "flag":
                return <FlagIcon scale={scale}/>
            case "arrow":
                return <ArrowIcon scale={scale}/>
            case "edit":
                return <EditIcon scale={scale}/>
            case "plus":
                return <PlusIcon scale={scale}/>
            case "filter":
                return <FilterIcon scale={scale}/>
            case "blueprint":
                return <BlueprintIcon scale={scale}/>
            case "hamburger":
                return <HamburgerIcon scale={scale}/>
            case "leave":
                return <LeaveIcon scale={scale}/>
            case "next":
                return <NextIcon scale={scale}/>
            case "prev":
                return <PrevIcon scale={scale}/>
            case "down":
                return <DownIcon scale={scale}/>
            case "up":
                return <UpIcon scale={scale}/>
            case "sync":
                return <SyncIcon scale={scale}/>
            case "star":
                return <StarIcon scale={scale}/>
            case "emptyStar":
                return <EmptyStarIcon scale={scale}/>
            case "sum":
                return <SumIcon scale={scale}/>
            case "tool":
                return <ToolIcon scale={scale}/>
            case "link":
                return <LinkIcon scale={scale}/>
            case "title":
                return <TitleIcon scale={scale}/>
            case "wave":
                return <WaveIcon scale={scale}/>
            case "avg":
                return <AvgIcon scale={scale}/>
            case "trophy":
                return <TrophyIcon scale={scale}/>
            case "watch":
                return <WatchIcon scale={scale}/>
            case "drift":
                return <DriftIcon scale={scale}/>
            case "warning":
                return <WarningIcon scale={scale}/>
            case "joker":
                return <JokerIcon scale={scale}/>
            case "youtube":
                return <YouTubeIcon scale={scale}/>
            case "sortable":
                return <SortableIcon scale={scale}/>
            case "finishTarget":
                return <FinishTargetIcon scale={scale}/>
            case "speedDriftTarget":
                return <SpeedDriftTargetIcon scale={scale}/>
            case "angleDriftTarget":
                return <AngleDriftTargetIcon scale={scale}/>
            case "360DriftTarget":
                return <ThreeSixtyDriftTargetIcon scale={scale}/>
            case "180DriftTarget":
                return <OneEightyDriftTargetIcon scale={scale}/>
            case "speaker":
                return <SpeakerIcon scale={scale}/>
            case "speakerDisabled":
                return <SpeakerDisbaledIcon scale={scale}/>
            case "help":
                return <HelpIcon scale={scale}/>
            case "restore":
                return <RestoreIcon scale={scale}/>
            case "copy":
                return <CopyIcon scale={scale}/>
            case "sidebar":
                return <SidebarIcon scale={scale}/>
            case "location":
                return <LocationIcon scale={scale}/>
            case "online":
                return <OnlineIcon scale={scale}/>
            case "clipboard":
                return <ClipboardIcon scale={scale}/>
            case "clipboardCheck":
                return <ClipboardCheckIcon scale={scale}/>
            case "academy":
                return <AcademyIcon scale={scale}/>
            case "text":
                return <TextIcon scale={scale}/>
            case "gallery":
                return <GalleryIcon scale={scale}/>
            case "image":
                return <ImageIcon scale={scale}/>
            case "download":
                return <DownloadIcon scale={scale}/>
            case "upload":
                return <UploadIcon scale={scale}/>
            case "zoom":
                return <ZoomIcon scale={scale}/>
            case "zoomOut":
                return <ZoomOutIcon scale={scale}/>
            case "person":
                return <PersonIcon scale={scale}/>
            case "hidden":
                return <HiddenIcon scale={scale}/>
            case "visible":
                return <VisibleIcon scale={scale}/>
            case "testDriver":
                return <TestDriverIcon scale={scale}/>
            case "testDriverTiny":
                return <TestDriverTinyIcon scale={scale}/>
            case "proDriver":
                return <ProDriverIcon scale={scale}/>
            case "proDriverTiny":
                return <ProDriverTinyIcon scale={scale}/>
            case "clubDriver":
                return <ClubDriverIcon scale={scale}/>
            case "clubDriverTiny":
                return <ClubDriverTinyIcon scale={scale}/>
            case "translate":
                return <TranslateIcon scale={scale}/>
            case "shift":
                return <ShiftIcon scale={scale}/>
            case "search":
                return <SearchIcon scale={scale}/>
            case "people":
                return <PeopleIcon scale={scale}/>
            case "live":
                return <LiveIcon scale={scale}/>
            case "megaphone":
                return <MegaphoneIcon scale={scale}/>
            case "mail":
                return <MailIcon scale={scale}/>
            case "pending":
                return <PendingIcon scale={scale}/>
            case "judgeUser":
                return <JudgeUserIcon scale={scale}/>
            case "judgeUserTiny":
                return <JudgeUserTinyIcon scale={scale}/>
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            ref={rootRef}
            className={"icon" + (props.className ? " " + props.className : "")}
            data-icon-color={props.iconColor}
            data-warning={props.warning}>
            {getIcon(props.type, props.scale ?? 1)}
            {props.stateIconType &&
                <div
                    className="icon-state-icon"
                    data-state-icon-color={props.stateIconColor}>
                    {getIcon(props.stateIconType, (props.scale ?? 1) * (props.stateIconScale ?? 0.6))}
                </div>}
            {props.tooltip &&
                <Tooltip
                    label={props.tooltip}
                    hoverElement={rootRef.current}/>}
        </div>
    );

}

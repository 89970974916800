import * as React from "react"
import {useRef} from "react"
import {useServices} from "../../../hooks/useServices"
import {StateValue} from "@webfruits/toolbox/dist/state/StateValue"
import {useForceUpdate} from "../../../hooks/useForceUpdate"
import {ContextMenuSelectable} from "../elements/ContextMenuSelectable"
import {ContextMenuGroup} from "../elements/ContextMenuGroup"
import {ContextMenu} from "../ContextMenu"
import {ContextMenuDivider} from "../elements/ContextMenuDivider"
import {ContextMenuLabel} from "../elements/ContextMenuLabel"
import {useSessionAnnouncer} from "../../../audio/annoucer/useSessionAnnouncer"
import {useDriverAnnouncer} from "../../../audio/annoucer/useDriverAnnouncer"
import {useSessionState} from "../../../hooks/useSessionState"
import {useSpeakerState} from "../../../audio/state/useSpeakerState"
import {SessionSpeakerMode} from "../../../../utils/types/SessionSpeakerMode"
import {useRaceAnnouncer} from "../../../audio/annoucer/useRaceAnnouncer";
import {useStateAnnouncer} from "../../../audio/annoucer/useStateAnnouncer";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {useAuthUser} from "../../../hooks/useAuthUser";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";

/******************************************************************
 * SessionSpeakerContextMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function SessionSpeakerContextMenu() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, state, speaker} = useServices()
    const {
        sessionMode,
        sessionFinishType,
        sessionTiming,
        sessionStartTime,
        sessionClassificationMode,
        sessionLaps,
        sessionNumJokerLaps,
        sessionAnnounceLeaderboardEveryLap,
        sessionAnnounceLapsRemainingEveryLap,
        sessionAnnounceTimeRemainingEverySeconds,
        sessionAnnounceDrivenTimeEveryLap
    } = useSessionState()
    const forceUpdate = useForceUpdate()
    const {authUserValidDriverLicense, authUserRole} = useAuthUser()
    const {speakerStateIconType, speakerStateIconColor} = useSpeakerState()

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const settings = useRef<StateValue<boolean>[]>([])

    /* ----------------------------------------------------------------
 	 * SPEAKER
 	 * --------------------------------------------------------------*/

    useDriverAnnouncer({
        visibleSettings: settings.current
    })
    useRaceAnnouncer({
        visibleSettings: settings.current
    })
    useSessionAnnouncer({
        visibleSettings: settings.current
    })
    useStateAnnouncer({
        visibleSettings: settings.current
    })

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function toggle(annoucementSetting: StateValue<boolean>) {
        annoucementSetting.setValue(!annoucementSetting.getValue())
        forceUpdate()
    }

    function speakerDisabled() {
        return settings.current.every(setting => !setting.getValue())
    }

    function setting(
        dictKey: string,
        setting: StateValue<boolean>,
        requiredDriverLicense: DriverLicenseType,
        mode: SessionSpeakerMode[] = [],
    ) {
        if (!showSettings(mode)) return null
        if (!settings.current.some(item => item === setting)) {
            settings.current.push(setting)
        }
        const needHigherLicenseType = DriverLicenseUtils.needHigherLicenseType(
            authUserValidDriverLicense,
            requiredDriverLicense,
            authUserRole)
        if (needHigherLicenseType) {
            setting.setValue(false)
        }
        return <ContextMenuSelectable
            label={dict(`speaker.menu.${dictKey}`)
                .replace("{ANNOUNCE_LEADERBOARD_EVERY_LAP}", sessionAnnounceLeaderboardEveryLap.toString())
                .replace("{ANNOUNCE_REMAINING_LAPS_EVERY_LAP}", sessionAnnounceLapsRemainingEveryLap.toString())
                .replace("{ANNOUNCE_REMAINING_TIME_EVERY_MINUTE}", (sessionAnnounceTimeRemainingEverySeconds / 60).toString())
                .replace("{ANNOUNCE_DRIVEN_TIME_EVERY_LAP}", (sessionAnnounceDrivenTimeEveryLap).toString())}
            onClick={needHigherDriverLicense => needHigherDriverLicense
                ? state.showSubscriptionUpgradeInfo.setValue({message: dict("subscription.upgrade.info.speaker.announcement")})
                : toggle(setting)}
            stopPropagation={true}
            requiredDriverLicense={requiredDriverLicense}
            selected={setting.getValue()}/>
    }

    function sessionSpeakerModes(): SessionSpeakerMode[] {
        let modes: SessionSpeakerMode[] = []
        if (sessionStartTime) {
            modes.push("startTimeCountdown")
        }
        if (sessionNumJokerLaps > 0) {
            modes.push("jokerLaps")
        }
        switch (sessionMode) {
            case "lap":
                modes.push("lapMode")
                break;
            case "race":
                modes.push("raceMode")
                switch (sessionClassificationMode) {
                    case "drivers":
                        modes.push("driversRaceMode")
                        if (sessionTiming == "sync") {
                            modes.push("driverSyncRace")
                        }
                        if (sessionTiming == "async") {
                            modes.push("driverAsyncRace")
                        }
                        break;
                    case "teams":
                        modes.push("teamRaceMode")
                        break;
                    case "solo":
                        modes.push("soloRaceMode")
                        switch (sessionFinishType) {
                            case "laps":
                                modes.push("soloLapsRaceMode")
                                break;
                            case "duration":
                                modes.push("soloDurationRaceMode")
                                break;
                        }
                        break;

                }
                break
            case "gymkhana":
                modes.push("gymkhanaMode")
                break;
        }
        switch (sessionTiming) {
            case "sync":
                switch (sessionMode) {
                    case "race":
                        modes.push("syncRace")
                        break
                }
                switch (sessionFinishType) {
                    case "laps":
                        modes.push("syncLaps")
                        break
                    case "duration":
                        modes.push("syncDuration")
                }
                break;
            case "async":
                switch (sessionFinishType) {
                    case "laps":
                        if (sessionLaps > 0) {
                            modes.push("fixedLaps")
                        }
                        break
                    case "duration":
                        if (sessionMode !== "gymkhana") {
                            modes.push("asyncDuration")
                        }
                }
                break;
        }
        return modes
    }

    function showSettings(mode: SessionSpeakerMode[]) {
        if (mode?.length > 0 && !mode?.some(type => {
            return sessionSpeakerModes()?.some(mode => {
                return mode === type
            })
        })) {
            return false
        }
        return true
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContextMenu
            key={authUserValidDriverLicense}
            type="icon"
            iconType={speakerDisabled() ? "speakerDisabled" : "speaker"}
            iconSize="small"
            stopPropagation={true}
            stateIconType={speakerStateIconType}
            stateIconColor={speakerStateIconColor}>
            <ContextMenuGroup
                name={dict("speaker.menu.state.announcements")}/>
            {setting("state.socketDisconnected", speaker.state.socketDisconnected, "free")}
            {setting("state.enteredDriver", speaker.state.enteredDriver, "free")}
            {setting("state.invalidDriver", speaker.state.invalidDriver, "free")}
            {setting("state.startedDriver", speaker.state.startedDriver, "free")}
            {setting("state.finishedDriver", speaker.state.finishedDriver, "free")}
            <ContextMenuGroup
                name={dict("speaker.menu.session.announcements")}/>
            {setting("session.falseStart", speaker.session.falseStart, "basic", ["raceMode"])}
            {setting("session.newBestLapTime", speaker.session.newBestLapTime, "basic", ["raceMode", "lapMode"])}
            {setting("session.newBestScore", speaker.session.newBestScore, "basic", ["gymkhanaMode"])}
            {setting("session.lapsRemaining", speaker.session.lapsRemaining, "premium", ["syncLaps"])}
            {setting("session.timeRemaining", speaker.session.timeRemaining, "premium", ["syncDuration"])}
            {setting("session.startTimeCountdown", speaker.session.startTimeCountdown, "basic", ["startTimeCountdown"])}
            {setting("session.positionGained", speaker.session.positionGained, "premium")}
            {setting("session.positionLost", speaker.session.positionLost, "premium")}
            {setting("session.jokerLap", speaker.session.jokerLap, "basic", ["jokerLaps"])}
            {showSettings(["syncRace"]) && <>
                <ContextMenuGroup
                    name={dict("speaker.menu.race.announcements")}/>
                {setting("race.bestLapTime", speaker.race.bestLapTime, "basic")}
                {setting("race.drivenTime", speaker.race.drivenTime, "basic")}
                {setting("race.wonBy", speaker.race.wonBy, "basic")}
                {setting("race.started", speaker.race.started, "basic")}
                {setting("race.finished", speaker.race.finished, "basic")}
                {setting("race.finalLeaderboard", speaker.race.finalLeaderboard, "basic")}
                {setting("race.currentLeaderboard", speaker.race.currentLeaderboard, "premium")}
            </>}
            <ContextMenuGroup
                name={dict("speaker.menu.driver.announcements")}/>
            {setting("driver.drivenTime", speaker.driver.drivenTime, "free", ["lapMode", "raceMode"])}
            {setting("driver.newBestDrivenTime", speaker.driver.newBestDrivenTime, "basic", ["driverAsyncRace"])}
            {setting("driver.alreadyDrivenTime", speaker.driver.alreadyDrivenTime, "premium", ["lapMode", "raceMode"])}
            {setting("driver.lapTime", speaker.driver.lapTime, "free", ["lapMode", "raceMode"])}
            {setting("driver.newBestLapTime", speaker.driver.newBestLapTime, "basic", ["lapMode", "raceMode"])}
            {setting("driver.stintDrivenLaps", speaker.driver.stintDrivenLaps, "basic", ["lapMode", "raceMode"])}
            {setting("driver.overallDrivenLaps", speaker.driver.overallDrivenLaps, "basic", ["syncLaps", "syncDuration"])}
            {setting("driver.position", speaker.driver.position, "premium", ["driversRaceMode"])}
            {setting("driver.gapToNext", speaker.driver.gapToNext, "premium", ["driverSyncRace"])}
            {setting("driver.gapToLeader", speaker.driver.gapToLeader, "premium", ["driverSyncRace"])}
            {setting("driver.gapToFollower", speaker.driver.gapToFollower, "premium", ["driverSyncRace"])}
            {setting("driver.lapsRemaining", speaker.driver.lapsRemaining, "premium", ["fixedLaps"])}
            {setting("driver.timeRemaining", speaker.driver.timeRemaining, "premium", ["asyncDuration"])}
            {setting("driver.totalScore", speaker.driver.totalScore, "free", ["gymkhanaMode"])}
            {setting("driver.targetName", speaker.driver.targetName, "basic", ["gymkhanaMode"])}
            {setting("driver.targetScore", speaker.driver.targetScore, "basic", ["gymkhanaMode"])}
            {setting("driver.currentStint", speaker.driver.newBestScore, "basic", ["gymkhanaMode"])}
            <ContextMenuDivider
                style={"normal"}/>
            <ContextMenuLabel
                label={dict("speaker.menu.voice.help")}
                icon="help"
                iconScale={0.8}
                onClick={() => state.showArticle.setValue(dict("article.id.speech"))}/>
            <ContextMenuLabel
                label={dict("speaker.menu.voice.settings")}
                icon="settings"
                iconScale={0.8}
                stateIconType={speakerStateIconType}
                stateIconColor={speakerStateIconColor}
                onClick={() => state.showSpeakerSettings.setValue(true)}/>
        </ContextMenu>
    )

}

import * as React from "react";
import {PinComponent} from "../../../../ui/utils/observer/PinComponent";
import {DriverLicenseInfo} from "../../../../ui/info/license/DriverLicenseInfo";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {IUserSubscription} from "../../../../../../shared/models/submodels/IUserSubscription";
import {DriverLicenseBadge} from "../../../../ui/info/license/DriverLicenseBadge";
import {DriverLicenseUtils} from "../../../../../../shared/utils/DriverLicenseUtils";
import {UserRoleType} from "../../../../../../shared/types/UserRoleType";
import {UserUtils} from "../../../../../../shared/utils/UserUtils";

/******************************************************************
 * UserSidebarInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UserSidebarInfo(props: {
    nick: string,
    name: string,
    role: UserRoleType,
    isRouteOwner?: boolean
    useSubscription?: boolean
    subscription?: IUserSubscription
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <PinComponent className="user-sidebar-info">
            <ContentLayout
                justifyContent="space-between"
                alignItems="center"
                gap="small"
                columnTemplate={props.useSubscription || UserUtils.doesRoleSkipSubscription(props.role)
                    ? "1fr min-content"
                    : null}>
                <ContentLayout gap="none">
                    <h2>{props.nick}</h2>
                </ContentLayout>
                {(props.useSubscription || UserUtils.doesRoleSkipSubscription(props.role)) &&
                    <DriverLicenseBadge
                        size="extra-large"
                        role={props.role}
                        licenseType={DriverLicenseUtils.validLicenseType(props.subscription)}/>}
            </ContentLayout>
            {props.useSubscription &&
                <DriverLicenseInfo
                    subscription={props.subscription}
                    isRouteOwner={props.isRouteOwner}
                    style={"sidebar"}/>}
        </PinComponent>
    );

}

import * as React from "react";
import {ITabbedContentChildProps} from "../../../../../ui/layout/tabbed/ITabbedContentChildProps";
import {Table} from "../../../../../ui/table/Table";
import {IUserData} from "../../../../../../../shared/models/IUserData";
import {ITableColumnDefinition} from "../../../../../ui/table/ITableColumnDefinition";
import {TimeUtils} from "../../../../../../../shared/utils/TimeUtils";
import {DriverLicenseUtils} from "../../../../../../../shared/utils/DriverLicenseUtils";
import {DriverLicenseType} from "../../../../../../../shared/types/DriverLicenseType";
import {IconType} from "../../../../../ui/icons/IconType";
import {ContentLayout} from "../../../../../ui/layout/content/ContentLayout";
import {Icon} from "../../../../../ui/icons/Icon";
import {TableValueCell} from "../../../../../ui/table/cells/value/TableValueCell";
import {FrontendRoute} from "../../../../../../../shared/routes/FrontendRoute";
import {useServices} from "../../../../../hooks/useServices";
import {SubscriptionUtils} from "../../../../../../utils/SubscriptionUtils";

/******************************************************************
 * AdminUserTable
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AdminUserTable(props: {
    tabbedContent?: ITabbedContentChildProps
    data?: IUserData[]
    isLoading?: boolean
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, router} = useServices();

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function getColumnsDefinition(): ITableColumnDefinition<IUserData>[] {
        return [
            {
                dataKey: "avatar",
                type: "image-circle",
                size: "60px"
            },
            {
                dataKey: "nick",
                sortKey: "nick",
                columnName: dict("user.nick"),
                type: "text",
                size: "2fr"
            },
            {
                dataKey: "path",
                sortKey: "path",
                columnName: dict("user.path"),
                type: "text",
                size: "2fr"
            },
            {
                dataKey: "email",
                sortKey: "email",
                columnName: dict("generic.email"),
                type: "text",
                size: "3.5fr",
            },
            {
                dataKey: "role",
                sortKey: "role",
                columnName: dict("user.role"),
                type: "text",
                size: "100px"
            },
            {
                sortKey: "_id",
                columnName: dict("user.created"),
                type: "text",
                size: "150px",
                value: (user: IUserData) => {
                    const timestamp = user._id.toString().substring(0, 8);
                    const date = new Date(parseInt(timestamp, 16) * 1000);
                    return TimeUtils.formatDate(date.toISOString())
                }
            },
            {
                type: "custom",
                size: "65px",
                columnName: dict("user.driverLicense"),
                align: "center",
                customCell: (key: number, user: IUserData) => {
                    const validLicense = DriverLicenseUtils.validLicenseType(user?.subscription)
                    const useSubscription = SubscriptionUtils.useSubscriptionService(user)

                    function iconType(license: DriverLicenseType | null): IconType {
                        switch (license) {
                            case "free":
                                return "testDriver"
                            case "basic":
                                return "clubDriver"
                            case "premium":
                                return "proDriver"
                        }
                    }

                    return <ContentLayout key={key} justifyItems="center">
                        {validLicense && useSubscription
                            ? <Icon type={iconType(validLicense)} scale={1.5}/>
                            : <TableValueCell type="text" value="–" style="normal"/>}
                    </ContentLayout>
                }
            },
            {
                type: "user-menu",
                size: "40px"
            },
        ]
    }

    function gotoToUserPage(user: IUserData) {
        router.showRoute(FrontendRoute.USER(user.path))
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <Table
            rowsData={props.data}
            style="admin"
            sortKey="_id"
            sortDirection="desc"
            showFilter={true}
            visibleRows={20}
            isLoadingData={props.isLoading}
            columnsDefinition={getColumnsDefinition()}
            onRowClick={gotoToUserPage}/>
    );

}

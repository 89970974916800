import * as React from "react";
import {useServices} from "../../../hooks/useServices";
import {ContextMenu} from "../../../ui/context/ContextMenu";
import {ContextMenuLabel} from "../../../ui/context/elements/ContextMenuLabel";
import {FrontendConfig} from "../../../../core/FrontendConfig";
import {ContextMenuDivider} from "../../../ui/context/elements/ContextMenuDivider";
import {useNotificationState} from "../../../hooks/useNotificationState";
import {LanguageSelector} from "../../../ui/form/elements/language/LanguageSelector";

/******************************************************************
 * MetaBarMenu
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MetaBarMenu() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {state, dict} = useServices()
    const {setNotification} = useNotificationState()

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function copyCurrentLocationToClipboard() {
        try {
            navigator.clipboard.writeText(window.location.href)
                .then(async () => {
                    setNotification({type: "info", message: dict("meta.currentLocationToClipboard.copied")})
                })
        } catch (e) {
            setNotification({type: "info", message: dict("meta.currentLocationToClipboard.copyToClipboardFailed")})
        }
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <nav className="metabar-menu">
            <ContextMenu
                type="icon"
                stopPropagation={true}
                iconType="menuDots">
                <ContextMenuLabel
                    label={dict("meta.homepage.title")}
                    onClick={() => state.showArticle.setValue(dict("article.id.homepage"))}/>
                <ContextMenuDivider style="normal"/>
                <ContextMenuLabel
                    label={dict("meta.imprint.termsofuse")}
                    onClick={() => state.showArticle.setValue(dict("article.id.termsofuse"))}/>
                <ContextMenuLabel
                    label={dict("meta.imprint.title")}
                    onClick={() => state.showArticle.setValue(dict("article.id.imprint"))}/>
                <ContextMenuLabel
                    label={dict("meta.privacy.title")}
                    onClick={() => state.showArticle.setValue(dict("article.id.privacy"))}/>
                <ContextMenuDivider style="normal"/>
                <ContextMenuLabel
                    label={dict("meta.report.abuse.title")}
                    onClick={() => state.showContact.setValue({sendMessageTo: "service", messageType: "abuse"})}/>
                <ContextMenuLabel
                    label={dict("meta.support.title")}
                    onClick={() => state.showContact.setValue({sendMessageTo: "service", messageType: "support"})}/>
                <ContextMenuDivider style="normal"/>
                <ContextMenuLabel
                    label={dict("meta.currentLocationToClipboard.title")}
                    onClick={copyCurrentLocationToClipboard}/>
                <ContextMenuDivider style="normal"/>
                <ContextMenuLabel
                    label={dict("meta.version.title")
                        .replace("{VERSION}", FrontendConfig.SERVER_VERSION)}
                    onClick={() => state.showVersion.setValue(true)}/>
                <ContextMenuLabel
                    label={dict("meta.restart.title")}
                    onClick={() => window.location.reload()}/>
                <ContextMenuDivider style="normal"/>
                <LanguageSelector size="small" style="list"/>
            </ContextMenu>
        </nav>
    );

}

import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {IUserData} from "../../../../../shared/models/IUserData";
import {useServices} from "../../../hooks/useServices";
import {FrontendRoute} from "../../../../../shared/routes/FrontendRoute";
import {GroupMemberRoleType} from "../../../../../shared/types/GroupMemberRoleType";
import {Picture} from "../../image/Picture";
import {SessionDriverStateType} from "../../../../../shared/types/SessionDriverStateType";
import {DriverLicenseBadge, DriverLicenseBadgeSize} from "../license/DriverLicenseBadge";
import {DriverLicenseType} from "../../../../../shared/types/DriverLicenseType";
import {SubscriptionUtils} from "../../../../utils/SubscriptionUtils";
import {DriverLicenseUtils} from "../../../../../shared/utils/DriverLicenseUtils";
import {Tooltip} from "../../tooltip/Tooltip";
import {Icon} from "../../icons/Icon";
import {UserUtils} from "../../../../../shared/utils/UserUtils";

/******************************************************************
 * UserInfo
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function UserInfo(props: {
    user: IUserData
    useSubscription?: boolean
    validDriverLicense?: DriverLicenseType
    groupMemberRole?: GroupMemberRoleType
    driverState?: SessionDriverStateType
    clickToShowProfile?: boolean
    onClick?: (user: IUserData) => void
    showRemoveIconOnHover?: boolean
    style:
        | "metabar"
        | "small"
        | "small-light"
        | "small-framed"
        | "normal"
        | "avatar"
        | "avatar-only-small"
        | "avatar-only-mini"
        | "leaderboard-entry-modal"
}) {

    /* ----------------------------------------------------------------
 	 * REFS
 	 * --------------------------------------------------------------*/

    const rootRef = useRef<HTMLDivElement>(null)

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {router, dict} = useServices()

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [defaultImageSource, setDefaultImageSource] = useState<string | null>(null)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        switch (props.style) {
            case "metabar":
            case "avatar":
                break
            default:
                if (props.user?.avatar) return;
        }
        if (props.user) {
            setDefaultImageSource(require("ASSETS/images/default-user-avatar.png"))
            return
        }
        setDefaultImageSource(require("ASSETS/images/user-not-found-avatar.png"))
    }, [props.user])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function onClick(e: React.MouseEvent) {
        e.stopPropagation()
        if (props.onClick) {
            props.onClick(props.user)
        }
        if (props.clickToShowProfile && props.user?.path) {
            return router.showRoute(FrontendRoute.USER(props.user.path))
        }
        return null
    }

    function showNickname(): boolean {
        switch (props.style) {
            case "metabar":
            case "avatar-only-small":
            case "avatar-only-mini":
                return false

        }
        return true
    }

    function licenseSize(): DriverLicenseBadgeSize {
        switch (props.style) {
            case "avatar":
                return "large"
            case "avatar-only-small":
            case "avatar-only-mini":
                return "tiny"
        }
        return "normal"
    }

    function useSubscription(): boolean {
        return SubscriptionUtils.useSubscriptionService(props.user)
    }

    function validDriverLicense(): DriverLicenseType {
        return DriverLicenseUtils.validLicenseType(props.user?.subscription)
    }

    function showTooltip(): boolean {
        switch (props.style) {
            case "metabar":
                return true
        }
        return false
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div
            className="user-info"
            ref={rootRef}
            data-style={props.style}
            data-error={!props.user}
            data-interactive={props.clickToShowProfile || !!props.onClick}
            data-driver-state={props.driverState}
            onClick={onClick}>
            <div className="user-info-picture-box">
                <Picture
                    file={props.user?.avatar}
                    alt={props.user?.nick}
                    defaultSource={defaultImageSource}/>
                {(useSubscription() || UserUtils.doesRoleSkipSubscription(props.user?.role)) &&
                    <DriverLicenseBadge
                        licenseType={validDriverLicense()}
                        size={licenseSize()}
                        role={props.user.role}/>}
            </div>
            {showNickname() &&
                <h6>{props.user?.nick ?? dict("user.not.found")}</h6>}
            {showTooltip() &&
                <Tooltip
                    label={props.user?.nick ?? dict("user.not.found")}
                    hoverElement={rootRef.current}/>}
            {props.showRemoveIconOnHover &&
                <Icon
                    className="user-info-remove-icon"
                    type="cancel"
                    iconColor="red"
                    scale={0.9}/>}
        </div>
    );

}

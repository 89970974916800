import {IUserData} from "../models/IUserData";
import {UserPermissionType, UserPermissionTypes} from "../types/UserPermissionType";
import {PaddleEnviromentType} from "../types/PaddleEnviromentType";
import {UserRoleType} from "../types/UserRoleType";

/******************************************************************
 * UserUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class UserUtils {

    static hasPermission(user: IUserData, permissionRequest: UserPermissionType): boolean {
        if (!user || !user.permissions) {
            return false;
        }
        return user.permissions.includes(permissionRequest)
    }

    static doesRoleSkipSubscription(role: UserRoleType): boolean {
        switch (role) {
            case "judge":
                return true
        }
        return false
    }

    static useSubscriptionService(
        user: IUserData,
        isPaddleAvaiable: boolean,
        paddleEnvironment: PaddleEnviromentType,
        paddleEnabledPerDefault: boolean
    ): boolean {
        if (!user) return false
        if (!isPaddleAvaiable) return false;
        if (UserUtils.doesRoleSkipSubscription(user.role)) return false
        if (UserUtils.hasPermission(user, "subscription:skip")) return false
        switch (paddleEnvironment) {
            case "sandbox":
                if (!UserUtils.hasPermission(user, "subscription:sandbox.tester")) {
                    return false
                }
                break
            case "production":
                if (!paddleEnabledPerDefault) {
                    if (!UserUtils.hasPermission(user, "subscription:production.force")) {
                        return false
                    }
                }
                break
        }
        return true
    }

    static availableUserPermissions(
        paddleEnvironment: PaddleEnviromentType,
        paddleEnabledPerDefault: boolean
    ): UserPermissionType[] {
        return UserPermissionTypes
            .filter(permission => {
                switch (paddleEnvironment) {
                    case "sandbox":
                        switch (permission) {
                            case "subscription:production.force":
                                return false
                        }
                        return true
                    case "production":
                        switch (permission) {
                            case "subscription:sandbox.tester":
                                return false
                        }
                        if (paddleEnabledPerDefault) {
                            switch (permission) {
                                case "subscription:production.force":
                                    return false
                            }
                        }
                        return true
                }
            })
    }


}

import {StateValue} from "@webfruits/toolbox/dist/state/StateValue"
import {IUserData} from "../../../shared/models/IUserData"
import {FrontendServices} from "../FrontendServices"
import {IFrontendRoute} from "../../utils/interfaces/IFrontendRoute"
import {IMessageModal} from "../../components/systems/modals/message/IMessageModal"
import {IEventData} from "../../../shared/models/IEventData"
import {IConfirmModal} from "../../components/systems/modals/confirm/IConfirmModal"
import {ISessionData} from "../../../shared/models/ISessionData";
import {ITeamData} from "../../../shared/models/submodels/ITeamData";
import {ITrackData} from "../../../shared/models/submodels/ITrackData";
import {ITrackFilterData} from "../../../shared/models/submodels/ITrackFilterData";
import {IGroupData} from "../../../shared/models/IGroupData";
import {ICarData} from "../../../shared/models/ICarData";
import {ISessionLeaderboardEntry} from "../../../shared/types/ISessionLeaderboardEntry";
import {EventChildrenType} from "../../../shared/types/EventChildrenType";
import {IResultData} from "../../../shared/models/IResultData";
import {ILogEntryData} from "../../../shared/types/ILogEntryData";
import {SocketConnectionType} from "../../utils/types/SocketConnectionType";
import {INotification} from "../../utils/interfaces/INotification";
import {IMediaViewerItem} from "../../components/systems/modals/medieviewer/item/IMediaViewerItem";
import {ISubscriptionChanged} from "../../utils/interfaces/ISubscriptionChanged";
import {ISubscriptionUpdatePreview} from "../../utils/interfaces/ISubscriptionUpdatePreview";
import {AuthUserSettingType} from "../../utils/types/AuthUserSettingType";
import {IPaddleCheckout} from "../../utils/interfaces/IPaddleCheckout";
import {
    ISubscriptionUpgradeInfoData
} from "../../components/systems/modals/subscription/upgradeinfo/ISubscriptionUpgradeInfoData";
import {LanguageType} from "../../../shared/types/LanguageType";
import {LanguageFrontendUtils} from "../../utils/LanguageFrontendUtils";
import {MongoObjectIDType} from "../../../shared/types/MongoObjectIDType";
import {IContactModal} from "../../components/systems/modals/contact/IContactModal";
import {IMapLocationModal} from "../../components/systems/modals/map/IMapLocationModal";
import {IStintData} from "../../../shared/models/IStintData";
import {LoginStateType} from "../../utils/types/LoginStateType";

/******************************************************************
 * FrontendState
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class FrontendState {

    /* ----------------------------------------------------------------
 	 * LANGUAGE
 	 * --------------------------------------------------------------*/

    public readonly language = new StateValue<LanguageType>(LanguageFrontendUtils.getLanguageFromHTML(null))

    /* ----------------------------------------------------------------
 	 * MODALS
 	 * --------------------------------------------------------------*/

    public readonly showAcademy = new StateValue<string | boolean>(null)
    public readonly showAddMailToWhitelist = new StateValue<boolean>(false)
    public readonly showArticle = new StateValue<MongoObjectIDType>(null)
    public readonly showMail = new StateValue<MongoObjectIDType | boolean>(false)
    public readonly showMailViewer = new StateValue<MongoObjectIDType>(null)
    public readonly showAuthUserSettings = new StateValue<AuthUserSettingType>(null)
    public readonly showCar = new StateValue<ICarData | boolean>(null)
    public readonly showConfirm = new StateValue<IConfirmModal>()
    public readonly showCreateEvent = new StateValue<boolean>(null)
    public readonly showCreateFolder = new StateValue<boolean>(null)
    public readonly showCreateGroup = new StateValue<boolean>(false)
    public readonly showCreateSession = new StateValue<boolean>(null)
    public readonly showCreateFollowSession = new StateValue<{ originalSession: ISessionData }>(null)
    public readonly showCreateTrack = new StateValue<boolean>(null)
    public readonly showEditSessionPath = new StateValue<ISessionData>(null)
    public readonly showEventSettings = new StateValue<boolean>(false)
    public readonly showFavorites = new StateValue<boolean>(false)
    public readonly showForgotPassword = new StateValue<boolean>(false)
    public readonly showGroupPreview = new StateValue<MongoObjectIDType>(null)
    public readonly showGroupSettings = new StateValue<boolean>(null)
    public readonly showHelp = new StateValue<string>(null)
    public readonly showLeaderboardEntry = new StateValue<ISessionLeaderboardEntry>(null)
    public readonly showLogin = new StateValue<LoginStateType>(null)
    public readonly showLogout = new StateValue<boolean>(false)
    public readonly showMediaViewer = new StateValue<IMediaViewerItem[]>()
    public readonly showMessage = new StateValue<IMessageModal>(null)
    public readonly showReloadFrontend = new StateValue<Error>(null)
    public readonly showRequestGroupMembership = new StateValue<boolean>(false)
    public readonly showResultSetup = new StateValue<boolean | IResultData>(null)
    public readonly showEventsFinder = new StateValue<boolean>(false)
    public readonly showGroupsFinder = new StateValue<boolean>(false)
    public readonly showSelectDrivers = new StateValue<boolean>(false)
    public readonly showSessionLog = new StateValue<boolean>(false)
    public readonly showSessionSettings = new StateValue<boolean>(false)
    public readonly showSpeakerSettings = new StateValue<boolean>(false)
    public readonly showSubscriptionChanged = new StateValue<ISubscriptionChanged>(null)
    public readonly showSubscriptionChangePreview = new StateValue<ISubscriptionUpdatePreview>(null)
    public readonly showSubscriptionCheckout = new StateValue<IPaddleCheckout>(null)
    public readonly showSubscriptionInfo = new StateValue<boolean>(false)
    public readonly showSubscriptionPlans = new StateValue<boolean>(false)
    public readonly showSubscriptionUpdateAddress = new StateValue<boolean>(false)
    public readonly showSubscriptionUpdatePaymentMethod = new StateValue<boolean>(false)
    public readonly showSubscriptionUpgradeInfo = new StateValue<ISubscriptionUpgradeInfoData>(null)
    public readonly showTeam = new StateValue<ITeamData | boolean>()
    public readonly showTrackFilter = new StateValue<boolean | ITrackFilterData>(null)
    public readonly showTrackSettings = new StateValue<boolean>(false)
    public readonly showUpdateApp = new StateValue<boolean>(false)
    public readonly showUserPermissions = new StateValue<IUserData>(null)
    public readonly showUserRoles = new StateValue<IUserData>(null)
    public readonly showVerifyAccount = new StateValue<boolean>(false)
    public readonly showVersion = new StateValue<boolean>(false)
    public readonly showContact = new StateValue<IContactModal>(null)
    public readonly showEventParticipationRequest = new StateValue<IEventData>(null)
    public readonly showMapLocation = new StateValue<IMapLocationModal>(null)
    public readonly showRaceDirector = new StateValue<IStintData>(null)
    public readonly showAcceptLegal = new StateValue<boolean>(false)

    /* ----------------------------------------------------------------
 	 * USER
 	 * --------------------------------------------------------------*/

    public readonly authUser = new StateValue<IUserData>(null)
    public readonly authUserDataBeforeUpdate = new StateValue<IUserData>(null)
    public readonly routeOwner = new StateValue<IUserData | IGroupData>(null)

    /* ----------------------------------------------------------------
 	 * GROUP
 	 * --------------------------------------------------------------*/

    public readonly group = new StateValue<IGroupData>(null)

    /* ----------------------------------------------------------------
 	 * ROUTE
 	 * --------------------------------------------------------------*/

    public readonly route = new StateValue<IFrontendRoute>()

    /* ----------------------------------------------------------------
 	 * EVENT
 	 * --------------------------------------------------------------*/

    public readonly event = new StateValue<IEventData>(null)
    public readonly eventChildren = new StateValue<EventChildrenType>(null);
    public readonly hasLiveEvents = new StateValue<boolean>(false)

    /* ----------------------------------------------------------------
 	 * SESSION
 	 * --------------------------------------------------------------*/

    public readonly session = new StateValue<ISessionData>(null)
    public readonly sessionLeaderboard = new StateValue<ISessionLeaderboardEntry[]>(null)
    public readonly sessionLogData = new StateValue<ILogEntryData[]>(null)

    /* ----------------------------------------------------------------
 	 * RESULT
 	 * --------------------------------------------------------------*/

    public readonly result = new StateValue<IResultData>(null)

    /* ----------------------------------------------------------------
 	 * TRACK
 	 * --------------------------------------------------------------*/

    public readonly track = new StateValue<ITrackData>(null)

    /* ----------------------------------------------------------------
 	 * SOCKET
 	 * --------------------------------------------------------------*/

    public readonly socket = new StateValue<SocketConnectionType>("connecting")

    /* ----------------------------------------------------------------
 	 * NOTIFICATIONS
 	 * --------------------------------------------------------------*/

    public readonly notification = new StateValue<INotification>(null)

    /* ----------------------------------------------------------------
 	 * CONTEXT MENU
 	 * --------------------------------------------------------------*/

    public readonly contextMenuID = new StateValue<number>(0)

    /* ----------------------------------------------------------------
 	 * MOBILE MENU
 	 * --------------------------------------------------------------*/

    public readonly showMobileMenu = new StateValue<boolean>(false)
    public readonly swipingMobileMenuDeltaX = new StateValue<number>(null)

    /* ----------------------------------------------------------------
 	 * CORE ERROR
 	 * --------------------------------------------------------------*/

    public readonly coreErrorMessage = new StateValue<string>()

    /* ----------------------------------------------------------------
 	 * CONSTRUCTOR
 	 * --------------------------------------------------------------*/

    constructor(private _frontend: FrontendServices) {
        this.authUser.onChangeSignal.add(() => this.onUserChanged())
    }

    /* ----------------------------------------------------------------
 	 * EVENTS
 	 * --------------------------------------------------------------*/

    private async onUserChanged() {
        const authUser: IUserData = this.authUser.getValue()
        this.language.setValue(LanguageFrontendUtils.getLanguageFromHTML(authUser))
        if (authUser) {
            switch (authUser.state) {
                case "unverified":
                    this.showVerifyAccount.setValue(true)
                    return
            }
        } else {
            await this._frontend.api.user.logout()
        }
    }
}

import * as React from "react";

/******************************************************************
 * JudgeUserTinyIcon
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function JudgeUserTinyIcon(props: {
    scale?: number
}) {

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <div className="judge-user-tiny-icon">
            <svg width={20 * (props.scale ?? 1)} height={21 * (props.scale ?? 1)} viewBox="0 0 20 21" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <rect x="5" y="8.94971" width="10" height="4" rx="2" fill="#000000"/>
            </svg>
        </div>
    );

}

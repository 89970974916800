import * as React from "react";
import {useEffect, useState} from "react";
import {LoginModal} from "./login/LoginModal";
import {HelpModal} from "./help/HelpModal";
import {ArticleModal} from "./article/ArticleModal";
import {ForgotPasswordModal} from "./forgotpassword/ForgotPasswordModal";
import {VerifyAccountModal} from "./verify/VerifyAccountModal";
import {MessageModal} from "./message/MessageModal";
import {CreateEventModal} from "./event/CreateEventModal";
import {useServices} from "../../hooks/useServices";
import {ConfirmModal} from "./confirm/ConfirmModal";
import {SelectDriversModal} from "./driver/SelectDriversModal";
import {CreateSessionModal} from "./session/CreateSessionModal";
import {CreateFolderModal} from "./folder/CreateFolderModal";
import {EditSessionPathModal} from "./session/EditSessionPathModal";
import {TeamModal} from "./team/TeamModal";
import {CreateTrackModal} from "./track/CreateTrackModal";
import {TrackFilterModal} from "./track/TrackFilterModal";
import {CreateGroupModal} from "./group/CreateGroupModal";
import {CarModal} from "./car/CarModal";
import {ResultSetupModal} from "./result/ResultSetupModal";
import {LeaderboardEntryModal} from "./leaderboardentry/LeaderboardEntryModal";
import {SpeakerSettingsModal} from "./speaker/SpeakerSettingsModal";
import {VersionModal} from "./version/VersionModal";
import {ReloadFrontendModal} from "./reload/ReloadFrontendModal";
import {SessionLogModal} from "./session/SessionLogModal";
import {EventSettingsModal} from "./event/EventSettingsModal";
import {SessionSettingsModal} from "./session/SessionSettingsModal";
import {TrackSettingsModal} from "./track/TrackSettingsModal";
import {AuthUserSettingsModal} from "./user/AuthUserSettingsModal";
import {GroupSettingsModal} from "./group/GroupSettingsModal";
import {UpdateAppModal} from "./update/UpdateAppModal";
import {AcademyModal} from "./academy/AcademyModal";
import {MediaViewerModal} from "./medieviewer/MediaViewerModal";
import {FavoritesModal} from "./favorites/FavoritesModal";
import {RequestGroupMembershipModal} from "./group/RequestGroupMembershipModal";
import {UserPermissionsModal} from "./user/UserPermissionsModal";
import {LogoutModal} from "./logout/LogoutModal";
import {AddMailToWhitelistModal} from "./whitelist/AddMailToWhitelistModal";
import {SubscriptionPlansModal} from "./subscription/plans/SubscriptionPlansModal";
import {SubscriptionChangedModal} from "./subscription/changed/SubscriptionChangedModal";
import {SubscriptionChangePreviewModal} from "./subscription/preview/SubscriptionChangePreviewModal";
import {SubscriptionCheckoutModal} from "./subscription/checkout/SubscriptionCheckoutModal";
import {
    SubscriptionUpdatePaymentMethodeModal
} from "./subscription/paymentmethode/SubscriptionUpdatePaymentMethodeModal";
import {SubscriptionUpdateAddressModal} from "./subscription/address/SubscriptionUpdateAddressModal";
import {SubscriptionUpgradeInfoModal} from "./subscription/upgradeinfo/SubscriptionUpgradeInfoModal";
import {SubscriptionInfoModal} from "./subscription/info/SubscriptionInfoModal";
import {GroupPreviewModal} from "./group/GroupPreviewModal";
import {ContactModal} from "./contact/ContactModal";
import {EventParticipationRequestModal} from "./event/EventParticipationRequestModal";
import {EventsFinderModal} from "./events/EventsFinderModal";
import {GroupsFinderModal} from "./groups/GroupsFinderModal";
import {MapLocationModal} from "./map/MapLocationModal";
import {RaceDirectorModal} from "./racedirector/RaceDirectorModal";
import {MailModal} from "./mail/MailModal";
import {LoginStateType} from "../../../utils/types/LoginStateType";
import {CreateFollowSessionModal} from "./session/CreateFollowSessionModal";
import {AcceptLegalModal} from "./legal/AcceptLegalModal";
import {MailViewerModal} from "./mail/MailViewerModal";
import {UserRolesModal} from "./user/UserRolesModal";

/******************************************************************
 * ModalSystem
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function ModalSystem() {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {state} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [showVerifyAccount, setShowVerifyAccount] = useState<boolean>(false);
    const [showForgotPassword, setShowForgetPassword] = useState<boolean>(false);
    const [showArticle, setShowArticle] = useState<boolean>(false);
    const [showMail, setShowMail] = useState<boolean>(false);
    const [showMailViewer, setShowMailViewer] = useState<boolean>(false);
    const [showAuthUserSettings, setShowAuthUserSettings] = useState<boolean>(false);
    const [showGroupSettings, setShowGroupSettings] = useState<boolean>(false);
    const [showHelp, setShowHelp] = useState<boolean>(false);
    const [showLogin, setShowLogin] = useState<LoginStateType>(null);
    const [showLogout, setShowLogout] = useState<boolean>(false);
    const [showMessage, setShowMessage] = useState<boolean>(false);
    const [showCreateEvent, setShowCreateEvent] = useState<boolean>(false);
    const [showEventSettings, setShowEventSettings] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [showSelectDrivers, setShowSelectDrivers] = useState<boolean>(false);
    const [showCreateSession, setShowCreateSession] = useState<boolean>(false);
    const [showCreateFollowSession, setShowCreateFollowSession] = useState<boolean>(false);
    const [showSessionSettings, setShowSessionSettings] = useState<boolean>(false);
    const [showCreateResult, setShowCreateEditResult] = useState<boolean>(false);
    const [showCreateFolder, setShowCreateFolder] = useState<boolean>(false);
    const [showEditSessionPath, setShowEditSessionPath] = useState<boolean>(false);
    const [showTeam, setShowTeam] = useState<boolean>(false);
    const [showCreateTrack, setShowCreateTrack] = useState<boolean>(false);
    const [showTrackSettings, setShowTrackSettings] = useState<boolean>(false);
    const [showTrackFilter, setShowTrackFilter] = useState<boolean>(false);
    const [showCreateGroup, setShowCreateGroup] = useState<boolean>(false);
    const [showGroupPreview, setShowGroupPreview] = useState<boolean>(false);
    const [showCar, setShowCar] = useState<boolean>(false);
    const [showLeaderboardEntry, setShowLeaderboardEntry] = useState<boolean>(false);
    const [showSpeakerSettings, setShowSpeakerSettings] = useState<boolean>(false);
    const [showVersion, setShowVersion] = useState<boolean>(false);
    const [showReloadFrontend, setShowReloadFrontend] = useState<boolean>(false);
    const [showSessionLog, setShowSessionLog] = useState<boolean>(false);
    const [showUpdateApp, setShowUpdateApp] = useState<boolean>(false);
    const [showAcademy, setShowAcademy] = useState<boolean>(false);
    const [showMediaViewer, setShowMediaViewer] = useState<boolean>(false);
    const [showFavorites, setShowFavorites] = useState<boolean>(false);
    const [showRequestGroupMembership, setShowRequestGroupMembership] = useState<boolean>(false);
    const [showUserPermissions, setShowUserPermissions] = useState<boolean>(false);
    const [showUserRoles, setShowUserRoles] = useState<boolean>(false);
    const [showAddMail, setShowAddMailToWhitelist] = useState<boolean>(false);
    const [showSubscriptionPlans, setShowSubscriptionPlans] = useState<boolean>(false);
    const [showSubscriptionChange, setShowSubscriptionChange] = useState<boolean>(false);
    const [showSubscriptionUpdatePreview, setShowSubscriptionUpdatePreview] = useState<boolean>(false);
    const [showSubscriptionCheckout, setShowSubscriptionCheckout] = useState<boolean>(null);
    const [showSubscriptionUpdatePaymentMethod, setShowSubscriptionUpdatePaymentMethod] = useState<boolean>(false);
    const [showSubscriptionUpdateAddress, setShowSubscriptionUpdateAddress] = useState<boolean>(false);
    const [showSubscriptionUpgradeInfo, setShowSubscriptionUpgradeInfo] = useState<boolean>(false);
    const [showSubscriptionInfo, setShowSubscriptionInfo] = useState<boolean>(false);
    const [showEventsFinder, setShowEventsFinder] = useState<boolean>(false);
    const [showGroupsFinder, setShowGroupsFinder] = useState<boolean>(false);
    const [showGroupContact, setShowGroupContact] = useState<boolean>(false);
    const [showEventParticipationRequest, setShowEventParticipationRequest] = useState<boolean>(false);
    const [showMapLocation, setShowMapLocation] = useState<boolean>(false);
    const [showRaceDirector, setShowRaceDirector] = useState<boolean>(false);
    const [showAcceptLegal, setShowAcceptLegal] = useState<boolean>(false);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        state.showVerifyAccount.onChangeSignal.add(updateStates);
        state.showForgotPassword.onChangeSignal.add(updateStates);
        state.showArticle.onChangeSignal.add(updateStates);
        state.showMail.onChangeSignal.add(updateStates);
        state.showAuthUserSettings.onChangeSignal.add(updateStates);
        state.showGroupSettings.onChangeSignal.add(updateStates);
        state.showHelp.onChangeSignal.add(updateStates);
        state.showLogin.onChangeSignal.add(updateStates);
        state.showLogout.onChangeSignal.add(updateStates);
        state.showMessage.onChangeSignal.add(updateStates);
        state.showCreateEvent.onChangeSignal.add(updateStates);
        state.showEventSettings.onChangeSignal.add(updateStates);
        state.showConfirm.onChangeSignal.add(updateStates);
        state.showSelectDrivers.onChangeSignal.add(updateStates);
        state.showCreateSession.onChangeSignal.add(updateStates);
        state.showCreateFollowSession.onChangeSignal.add(updateStates);
        state.showSessionSettings.onChangeSignal.add(updateStates);
        state.showResultSetup.onChangeSignal.add(updateStates);
        state.showCreateFolder.onChangeSignal.add(updateStates);
        state.showEditSessionPath.onChangeSignal.add(updateStates);
        state.showTeam.onChangeSignal.add(updateStates);
        state.showCreateTrack.onChangeSignal.add(updateStates);
        state.showTrackSettings.onChangeSignal.add(updateStates);
        state.showTrackFilter.onChangeSignal.add(updateStates);
        state.showCreateGroup.onChangeSignal.add(updateStates);
        state.showCar.onChangeSignal.add(updateStates);
        state.showLeaderboardEntry.onChangeSignal.add(updateStates);
        state.showSpeakerSettings.onChangeSignal.add(updateStates);
        state.showVersion.onChangeSignal.add(updateStates);
        state.showReloadFrontend.onChangeSignal.add(updateStates);
        state.showSessionLog.onChangeSignal.add(updateStates);
        state.showUpdateApp.onChangeSignal.add(updateStates);
        state.showAcademy.onChangeSignal.add(updateStates);
        state.showMediaViewer.onChangeSignal.add(updateStates);
        state.showFavorites.onChangeSignal.add(updateStates);
        state.showRequestGroupMembership.onChangeSignal.add(updateStates);
        state.showUserPermissions.onChangeSignal.add(updateStates);
        state.showUserRoles.onChangeSignal.add(updateStates);
        state.showAddMailToWhitelist.onChangeSignal.add(updateStates);
        state.showSubscriptionPlans.onChangeSignal.add(updateStates);
        state.showSubscriptionChanged.onChangeSignal.add(updateStates);
        state.showSubscriptionChangePreview.onChangeSignal.add(updateStates);
        state.showSubscriptionCheckout.onChangeSignal.add(updateStates);
        state.showSubscriptionUpdatePaymentMethod.onChangeSignal.add(updateStates);
        state.showSubscriptionUpdateAddress.onChangeSignal.add(updateStates);
        state.showSubscriptionUpgradeInfo.onChangeSignal.add(updateStates);
        state.showSubscriptionInfo.onChangeSignal.add(updateStates);
        state.showGroupsFinder.onChangeSignal.add(updateStates);
        state.showEventsFinder.onChangeSignal.add(updateStates);
        state.showGroupPreview.onChangeSignal.add(updateStates);
        state.showContact.onChangeSignal.add(updateStates);
        state.showEventParticipationRequest.onChangeSignal.add(updateStates);
        state.showMapLocation.onChangeSignal.add(updateStates);
        state.showRaceDirector.onChangeSignal.add(updateStates);
        state.showAcceptLegal.onChangeSignal.add(updateStates);
        state.showMailViewer.onChangeSignal.add(updateStates);
        updateStates();
    }, [])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function updateStates() {
        setShowVerifyAccount(state.showVerifyAccount.getValue());
        setShowForgetPassword(state.showForgotPassword.getValue())
        setShowArticle(state.showArticle.hasValue())
        setShowMail(state.showMail.getValue())
        setShowHelp(state.showHelp.hasValue())
        setShowAuthUserSettings(state.showAuthUserSettings.hasValue());
        setShowGroupSettings(state.showGroupSettings.getValue());
        setShowLogin(state.showLogin.getValue());
        setShowLogout(state.showLogout.getValue());
        setShowMessage(state.showMessage.hasValue());
        setShowCreateEvent(state.showCreateEvent.getValue());
        setShowEventSettings(state.showEventSettings.getValue());
        setShowConfirm(state.showConfirm.hasValue());
        setShowSelectDrivers(state.showSelectDrivers.getValue());
        setShowCreateSession(state.showCreateSession.getValue());
        setShowCreateFollowSession(state.showCreateFollowSession.hasValue());
        setShowSessionSettings(state.showSessionSettings.getValue());
        setShowCreateEditResult(state.showResultSetup.hasValue());
        setShowCreateFolder(state.showCreateFolder.getValue());
        setShowEditSessionPath(state.showEditSessionPath.hasValue());
        setShowTeam(state.showTeam.hasValue());
        setShowCreateTrack(state.showCreateTrack.getValue());
        setShowTrackSettings(state.showTrackSettings.getValue());
        setShowTrackFilter(state.showTrackFilter.hasValue())
        setShowCreateGroup(state.showCreateGroup.getValue())
        setShowCar(state.showCar.hasValue())
        setShowLeaderboardEntry(state.showLeaderboardEntry.hasValue())
        setShowSpeakerSettings(state.showSpeakerSettings.getValue())
        setShowVersion(state.showVersion.getValue())
        setShowReloadFrontend(state.showReloadFrontend.hasValue())
        setShowSessionLog(state.showSessionLog.getValue())
        setShowUpdateApp(state.showUpdateApp.getValue())
        setShowAcademy(state.showAcademy.hasValue())
        setShowMediaViewer(state.showMediaViewer.hasValue())
        setShowFavorites(state.showFavorites.getValue())
        setShowRequestGroupMembership(state.showRequestGroupMembership.getValue())
        setShowUserPermissions(state.showUserPermissions.hasValue())
        setShowUserRoles(state.showUserRoles.hasValue())
        setShowAddMailToWhitelist(state.showAddMailToWhitelist.getValue())
        setShowSubscriptionPlans(state.showSubscriptionPlans.getValue())
        setShowSubscriptionChange(state.showSubscriptionChanged.hasValue())
        setShowSubscriptionUpdatePreview(state.showSubscriptionChangePreview.hasValue())
        setShowSubscriptionCheckout(state.showSubscriptionCheckout.hasValue())
        setShowSubscriptionUpdatePaymentMethod(state.showSubscriptionUpdatePaymentMethod.getValue())
        setShowSubscriptionUpdateAddress(state.showSubscriptionUpdateAddress.getValue())
        setShowSubscriptionUpgradeInfo(state.showSubscriptionUpgradeInfo.hasValue())
        setShowSubscriptionInfo(state.showSubscriptionInfo.getValue())
        setShowEventsFinder(state.showEventsFinder.getValue())
        setShowGroupsFinder(state.showGroupsFinder.getValue())
        setShowGroupPreview(state.showGroupPreview.hasValue())
        setShowGroupContact(state.showContact.hasValue())
        setShowEventParticipationRequest(state.showEventParticipationRequest.hasValue())
        setShowMapLocation(state.showMapLocation.hasValue())
        setShowRaceDirector(state.showRaceDirector.hasValue())
        setShowAcceptLegal(state.showAcceptLegal.getValue())
        setShowMailViewer(state.showMailViewer.hasValue())
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return <>
        {showLogin && <LoginModal />}
        {showLogout && <LogoutModal />}
        {showHelp && <HelpModal />}
        {showArticle && <ArticleModal />}
        {showMail && <MailModal />}
        {showForgotPassword && <ForgotPasswordModal />}
        {showVerifyAccount && <VerifyAccountModal />}
        {showMessage && <MessageModal />}
        {showCreateEvent && <CreateEventModal />}
        {showEventSettings && <EventSettingsModal />}
        {showConfirm && <ConfirmModal />}
        {showSelectDrivers && <SelectDriversModal />}
        {showCreateSession && <CreateSessionModal />}
        {showCreateFollowSession && <CreateFollowSessionModal />}
        {showSessionSettings && <SessionSettingsModal />}
        {showCreateResult && <ResultSetupModal />}
        {showCreateFolder && <CreateFolderModal />}
        {showEditSessionPath && <EditSessionPathModal />}
        {showTeam && <TeamModal />}
        {showCreateTrack && <CreateTrackModal />}
        {showTrackSettings && <TrackSettingsModal />}
        {showTrackFilter && <TrackFilterModal />}
        {showCreateGroup && <CreateGroupModal />}
        {showCar && <CarModal />}
        {showLeaderboardEntry && <LeaderboardEntryModal />}
        {showSpeakerSettings && <SpeakerSettingsModal />}
        {showVersion && <VersionModal />}
        {showReloadFrontend && <ReloadFrontendModal />}
        {showSessionLog && <SessionLogModal />}
        {showAuthUserSettings && <AuthUserSettingsModal />}
        {showGroupSettings && <GroupSettingsModal />}
        {showUpdateApp && <UpdateAppModal />}
        {showAcademy && <AcademyModal />}
        {showMediaViewer && <MediaViewerModal />}
        {showFavorites && <FavoritesModal />}
        {showRequestGroupMembership && <RequestGroupMembershipModal />}
        {showUserPermissions && <UserPermissionsModal />}
        {showUserRoles && <UserRolesModal />}
        {showAddMail && <AddMailToWhitelistModal />}
        {showSubscriptionPlans && <SubscriptionPlansModal/>}
        {showSubscriptionChange && <SubscriptionChangedModal/>}
        {showSubscriptionUpdatePreview && <SubscriptionChangePreviewModal/>}
        {showSubscriptionCheckout && <SubscriptionCheckoutModal/>}
        {showSubscriptionUpdatePaymentMethod && <SubscriptionUpdatePaymentMethodeModal/>}
        {showSubscriptionUpdateAddress && <SubscriptionUpdateAddressModal/>}
        {showSubscriptionUpgradeInfo && <SubscriptionUpgradeInfoModal/>}
        {showSubscriptionInfo && <SubscriptionInfoModal/>}
        {showEventsFinder && <EventsFinderModal/>}
        {showGroupsFinder && <GroupsFinderModal/>}
        {showGroupPreview && <GroupPreviewModal />}
        {showGroupContact && <ContactModal />}
        {showEventParticipationRequest && <EventParticipationRequestModal />}
        {showMapLocation && <MapLocationModal {...state.showMapLocation.getValue()}/>}
        {showRaceDirector && <RaceDirectorModal {...state.showRaceDirector.getValue()}/>}
        {showAcceptLegal && <AcceptLegalModal />}
        {showMailViewer && <MailViewerModal />}
    </>

}

import * as React from "react";
import {useEffect, useState} from "react";
import {IUserData} from "../../../../../../shared/models/IUserData";
import {useServices} from "../../../../hooks/useServices";
import {useSocketSignals} from "../../../../hooks/useSocketSignals";
import {AdminUserTable} from "./table/AdminUserTable";
import {ActionBar} from "../../../../ui/bar/ActionBar";
import {SelectInput, SelectInputOptionGroupType} from "../../../../ui/form/elements/select/SelectInput";
import {UserPermissionTypes, UserPermissionType} from "../../../../../../shared/types/UserPermissionType";
import {Headline} from "../../../../ui/text/headings/Headline";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {DriverLicenseUtils} from "../../../../../../shared/utils/DriverLicenseUtils";
import {Badge} from "../../../../ui/info/badge/Badge";
import {SubscriptionUtils} from "../../../../../utils/SubscriptionUtils";
import {UserUtils} from "../../../../../../shared/utils/UserUtils";
import {FrontendConfig} from "../../../../../core/FrontendConfig";

/******************************************************************
 * AdminUsers
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function AdminUsers() {

    /* ----------------------------------------------------------------
     * HOOKS
     * --------------------------------------------------------------*/

    const {dict, api, state} = useServices();
    const {serverAdminUpdate} = useSocketSignals()

    /* ----------------------------------------------------------------
 	 * STATS
 	 * --------------------------------------------------------------*/

    const [isBusy, setIsBusy] = useState(true);
    const [users, setUsers] = useState<IUserData[]>();
    const [filteredUsers, setFilteredUsers] = useState<IUserData[]>();
    const [filterGroups, setFilterGroups] = useState<SelectInputOptionGroupType[]>(getFilterGroups())
    const [currentFilter, setCurrentFilter] = useState<string>("verified")
    const [currentTitle, setCurrentTitle] = useState<string>()

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        state.showUserRoles.onChangeSignal.add(requestUpdateUsers)
        return () => {
            state.showUserRoles.onChangeSignal.remove(requestUpdateUsers)
        }
    }, [])

    useEffect(() => {
        loadUsers()
    }, [serverAdminUpdate])

    useEffect(() => {
        setFilterGroups(getFilterGroups())
        updateTable()
    }, [currentFilter, users])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function requestUpdateUsers() {
        if (state.showUserRoles.hasValue()) return
        loadUsers()
    }

    async function loadUsers() {
        setIsBusy(true);
        const users = await api.user.getAll();
        setUsers(users);
        setIsBusy(false);
    }

    function updateTable() {
        const options = filterGroups?.map(group => group.options).flat()
        const optionLabel = options?.find(option => option.value == currentFilter)?.text
        setCurrentTitle(optionLabel)
        setFilteredUsers(getFilterUsers(currentFilter))
    }

    function getFilterGroups(): SelectInputOptionGroupType[] {
        return [
            {
                groupLabel: dict("admin.users.filters.group.verify.label"),
                options: [
                    {
                        value: "verified",
                        text: dict("admin.users.list.verified.title"),
                        badge: <Badge label={countUsers("verified")} color="white"/>
                    },
                    {
                        value: "unverified",
                        text: dict("admin.users.list.unverified.title"),
                        badge: <Badge label={countUsers("unverified")} color="white"/>
                    }
                ]
            }, {
                groupLabel: dict("user.role"),
                options: [
                    {
                        value: "admin",
                        text: dict("user.role.admin"),
                        badge: <Badge label={countUsers("admin")} color="white"/>
                    },
                    {
                        value: "judge",
                        text: dict("user.role.judge"),
                        badge: <Badge label={countUsers("judge")} color="white"/>
                    },
                    {
                        value: "member",
                        text: dict("user.role.member"),
                        badge: <Badge label={countUsers("member")} color="white"/>
                    }
                ]
            }, {
                groupLabel: dict("user.permissions.label"),
                options: UserUtils.availableUserPermissions(
                    FrontendConfig.PADDLE_ENVIRONMENT,
                    FrontendConfig.PADDLE_ENABLED_PER_DEFAULT
                ).map(permission => {
                    return {
                        value: permission,
                        text: dict("user.permission." + permission),
                        badge: <Badge label={countUsers(permission)} color="white"/>
                    }
                })
            }, {
                groupLabel: dict("admin.users.filters.group.license.label"),
                options: [
                    {
                        value: "free",
                        text: dict("plan.license.simple.free"),
                        badge: <Badge label={countUsers("free")} color="white"/>
                    },
                    {
                        value: "basic",
                        text: dict("plan.license.simple.basic"),
                        badge: <Badge label={countUsers("basic")} color="white"/>
                    },
                    {
                        value: "premium",
                        text: dict("plan.license.simple.premium"),
                        badge: <Badge label={countUsers("premium")} color="white"/>
                    }, {
                        value: "unpaid",
                        text: dict("plan.license.unpaid"),
                        badge: <Badge label={countUsers("unpaid")} color="white"/>
                    }
                ]
            }
        ]
    }

    function countUsers(filter: string): string {
        if (!users) return "0"
        const filteredUsers = getFilterUsers(filter)
        return filteredUsers.length.toString()
    }

    function getFilterUsers(filter: string): IUserData[] {
        if (!users) return []
        return users.filter(user => {
            if (filter == "verified") return user.state == "verified"
            if (filter == "unverified") return user.state == "unverified"
            if (filter == "admin") return user.role == "admin"
            if (filter == "member") return user.role == "member"
            if (filter == "judge") return user.role == "judge"
            if (UserPermissionTypes.includes(filter as any)) return user.permissions?.includes(filter as UserPermissionType)
            if (filter == "free") return SubscriptionUtils.useSubscriptionService(user) && DriverLicenseUtils.validLicenseType(user.subscription) == "free"
            if (filter == "basic") return SubscriptionUtils.useSubscriptionService(user) && DriverLicenseUtils.validLicenseType(user.subscription) == "basic"
            if (filter == "premium") return SubscriptionUtils.useSubscriptionService(user) && DriverLicenseUtils.validLicenseType(user.subscription) == "premium"
            if (filter == "unpaid") return SubscriptionUtils.useSubscriptionService(user) && DriverLicenseUtils.hasUnpaidSubscription(user.subscription)
            return false
        })
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout className="admin-users" gap="none">
            <Headline text={currentTitle} style="modal"/>
            <AdminUserTable
                tabbedContent={{name: dict("admin.users.list.verified.title")}}
                isLoading={isBusy}
                data={filteredUsers}/>
            <ActionBar location="main">
                <SelectInput
                    size={"small"}
                    style={"important"}
                    processing={isBusy}
                    defaultValue={currentFilter}
                    options={filterGroups}
                    readonly={isBusy}
                    onChange={value => setCurrentFilter(value as string)}/>
            </ActionBar>
        </ContentLayout>
    );

}

import * as React from "react";
import {useEffect, useState} from "react";
import {IMailData} from "../../../../../../shared/models/IMailData";
import {IUserData} from "../../../../../../shared/models/IUserData";
import {useServices} from "../../../../hooks/useServices";
import {ContentLayout} from "../../../../ui/layout/content/ContentLayout";
import {Headline} from "../../../../ui/text/headings/Headline";
import {IconButton} from "../../../../ui/buttons/icon/IconButton";
import {Badge} from "../../../../ui/info/badge/Badge";
import {LabelButton} from "../../../../ui/buttons/label/LabelButton";
import {MailUtils} from "../../../../../../shared/utils/MailUtils";

/******************************************************************
 * MailPreview
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function MailPreview(props: {
    mailData: IMailData
    recipient: IUserData
    recipients: IUserData[]
    recipientPreviewIndex: number
    onChangePreviewIndex: (index: number) => void
}) {

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const {dict, api} = useServices()

    /* ----------------------------------------------------------------
 	 * HOOKS
 	 * --------------------------------------------------------------*/

    const [htmlContent, setHtmlContent] = useState('');
    const [sending, setSending] = useState<boolean>(false)

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        if (!props.mailData) return
        loadRenderedMail()
    }, [props.mailData]);

    /* ----------------------------------------------------------------
     * METHODES
     * --------------------------------------------------------------*/

    async function loadRenderedMail() {
        if (!props.mailData) return
        const html = await api.mail.render(props.mailData._id, props.recipient._id)
        setHtmlContent(html)
    }

    function processedSubject(): string {
        let markdown = props.mailData?.subject ?? ""
        if (markdown.length == 0) {
            markdown = dict("mail.preview.default.subject")
        }
        return MailUtils.replacePlaceholders({
            renderedMail: markdown,
            recipientNick: props.recipient?.nick ?? ""
        })
    }

    function hasHeaderOptions(): boolean {
        return props.recipients?.length > 1 || props.mailData?.state == "template"
    }

    async function sendTestMail() {
        setSending(true)
        await api.mail.sendTestMail(props.mailData?._id)
        setSending(false)
    }

    /* ----------------------------------------------------------------
     * RENDER
     * --------------------------------------------------------------*/

    return (
        <ContentLayout
            className="mail-preview"
            framed={true}
            alignContent="start"
            rowTemplate="min-content min-content 1fr">
            <ContentLayout
                columnTemplate={hasHeaderOptions() ? "min-content min-content" : null}
                justifyContent="space-between">
                <Headline
                    text={dict("mail.preview.title")}
                    style="h5-underlined"/>
                {props.mailData?.state == "template" &&
                    <LabelButton
                        label={dict("mail.send.testmail.to.nick.label").replaceAll("{NICK}", props.recipient?.nick)}
                        style="primary-small"
                        progressing={sending}
                        onClick={sendTestMail}/>}
                {props.recipients?.length > 1 &&
                    <ContentLayout
                        columnTemplate="repeat(3, min-content)"
                        alignItems="center"
                        gap="tiny">
                        <IconButton
                            type="prev"
                            size="small"
                            onClick={() => props.onChangePreviewIndex(-1)}/>
                        <Badge
                            label={props.recipients?.length > 0
                                ? ((props.recipientPreviewIndex + 1) + " / " + props.recipients?.length?.toString())
                                : "–"}
                            color="white"/>
                        <IconButton
                            type="next"
                            size="small"
                            onClick={() => props.onChangePreviewIndex(1)}/>
                    </ContentLayout>
                }
            </ContentLayout>
            <Headline
                text={processedSubject()} style="h3"/>
            <iframe
                srcDoc={htmlContent}
                style={{
                    backgroundColor: 'white',
                    width: '100%',
                    height: '100%',
                    borderRadius: 5,
                    border: 'none'
                }}
                title="HTML Preview"/>
        </ContentLayout>
    );

}

import {useEffect, useState} from "react";
import {IUserData} from "../../../shared/models/IUserData";
import {IUserDiscord} from "../../../shared/models/submodels/IUserDiscord";
import {MongoObjectIDType} from "../../../shared/types/MongoObjectIDType";
import {useServices} from "./useServices";
import {IFileData} from "../../../shared/models/IFileData";
import {IFavoriteData} from "../../../shared/models/submodels/IFavoriteData";
import {UserPermissionType} from "../../../shared/types/UserPermissionType";
import {IUserSubscription} from "../../../shared/models/submodels/IUserSubscription";
import {DriverLicenseType} from "../../../shared/types/DriverLicenseType";
import {DriverLicenseUtils} from "../../../shared/utils/DriverLicenseUtils";
import {UserUtils} from "../../../shared/utils/UserUtils";
import {SubscriptionUtils} from "../../utils/SubscriptionUtils";
import {LanguageType} from "../../../shared/types/LanguageType";
import {LanguageFrontendUtils} from "../../utils/LanguageFrontendUtils";
import {IMultilangString} from "../../../shared/models/submodels/IMultilangString";
import {ObjectUtils} from "../../../shared/utils/ObjectUtils";
import {NewsletterStateType} from "../../../shared/types/NewsletterStateType";
import {UserRoleType} from "../../../shared/types/UserRoleType";

/******************************************************************
 * UserState Hook
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export function useAuthUser() {

    /* ----------------------------------------------------------------
 	 * CORE
 	 * --------------------------------------------------------------*/

    const {state} = useServices();

    /* ----------------------------------------------------------------
 	 * STATES
 	 * --------------------------------------------------------------*/

    const [authUserData, setAuthUserData] = useState<IUserData>(state.authUser.getValue());
    const [authUserPath, setAuthUserPath] = useState<string>(state.authUser.getValue()?.path);
    const [authUserAvatar, setAuthUserAvatar] = useState<IFileData>(state.authUser.getValue()?.avatar);
    const [authUserNick, setAuthUserNick] = useState<string>(state.authUser.getValue()?.nick);
    const [authUserName, setAuthUserName] = useState<string>(state.authUser.getValue()?.name);
    const [authUserRole, setAuthUserRole] = useState<UserRoleType>(state.authUser.getValue()?.role);
    const [authUserMail, setAuthUserMail] = useState<string>(state.authUser.getValue()?.email);
    const [authUserCapiPassword, setAuthUserCapiPassword] = useState<string>(state.authUser.getValue()?.capiPassword);
    const [authUserDiscord, setAuthUserDiscord] = useState<IUserDiscord>(state.authUser.getValue()?.discord);
    const [authUserID, setAuthUserID] = useState<MongoObjectIDType>(state.authUser.getValue()?._id);
    const [authUserIsAdmin, setAuthUserIsAdmin] = useState<boolean>(state.authUser.getValue()?.role == "admin");
    const [authUserExists, setAuthUserExists] = useState<boolean>(state.authUser.hasValue());
    const [authUserLanguage, setAuthUserLanguage] = useState<LanguageType>(language());
    const [authUserSpeakerNick, setAuthUserSpeakerNick] = useState<IMultilangString>(state.authUser.getValue()?.speakerNick);
    const [authUserOnboardingCompleted, setAuthUserOnboardingCompleted] = useState<boolean>(state.authUser.getValue()?.onboardingCompleted ?? false);
    const [authUserFavorites, setAuthUserFavorites] = useState<IFavoriteData[]>(getFavorites());
    const [authUserPermissions, setAuthUserPermissions] = useState<UserPermissionType[]>(state.authUser.getValue()?.permissions ?? []);
    const [authUserSubscription, setAuthUserSubscription] = useState<IUserSubscription>(getSubscription());
    const [authUserPaddleCustomerID, setAuthUserPaddleCustomerID] = useState<string>(state.authUser.getValue()?.paddleCustomerID ?? null);
    const [authUserPaddleCheckoutState, setAuthUserPaddleCheckoutState] = useState<string>(state.authUser.getValue()?.checkoutState ?? null);
    const [authUserHasUnpaidSubscription, setAuthUserHasUnpaidSubscription] = useState<boolean>(hasUnpaidSubscription());
    const [authUserValidDriverLicense, setAuthUserValidDriverLicense] = useState<DriverLicenseType>(getValidDriverLicense());
    const [authUserUseSubscription, setAuthUserUseSubscription] = useState<boolean>(useSubscription());
    const [authUserIsUpdating, setAuthUserIsUpdating] = useState<boolean>(false);
    const [authUserDiscountAlreadyUsed, setAuthUserDiscountAlreadyUsed] = useState<boolean>(state.authUser.getValue()?.discountAlreadyUsed ?? false);
    const [authUserNewsletter, setAuthUserNewsletter] = useState<NewsletterStateType>(state.authUser.getValue()?.newsletter ?? null);

    /* ----------------------------------------------------------------
 	 * EFFECTS
 	 * --------------------------------------------------------------*/

    useEffect(() => {
        update();
        state.authUser.onChangeSignal.add(update);
        state.authUserDataBeforeUpdate.onChangeSignal.add(update);
        return () => {
            state.authUser.onChangeSignal.remove(update);
            state.authUserDataBeforeUpdate.onChangeSignal.remove(update);
        }
    }, [])

    useEffect(() => {
        update();
    }, [state.authUser.getValue(), state.authUserDataBeforeUpdate.getValue()])

    useEffect(() => {
        state.authUserDataBeforeUpdate.setValue(authUserIsUpdating ? state.authUser.getValue() : null)
    }, [authUserIsUpdating])

    /* ----------------------------------------------------------------
 	 * METHODES
 	 * --------------------------------------------------------------*/

    function hasUnpaidSubscription() {
        const user: IUserData = state.authUser.getValue();
        if (!user?.subscription?.driverLicense) return false;
        return user?.subscription?.driverLicense != "free" && !user?.subscription?.isPaid
    }

    function getSubscription(): IUserSubscription {
        const user: IUserData = state.authUser.getValue()
        if (!user?.subscription?.driverLicense) {
            return {driverLicense: "free"}
        }
        return state.authUser.getValue()?.subscription ?? {driverLicense: "free"}
    }

    function getValidDriverLicense(): DriverLicenseType {
        const user: IUserData = state.authUser.getValue();
        if (SubscriptionUtils.skipSubscriptionService(user)) {
            return null
        }
        return DriverLicenseUtils.validLicenseType(user?.subscription)
    }

    function useSubscription(): boolean {
        return SubscriptionUtils.useSubscriptionService(authUserData)
    }

    function language(): LanguageType {
        return LanguageFrontendUtils.getLanguageFromHTML(state.authUser.getValue())
    }

    function getFavorites(): IFavoriteData[] {
        const favorites = state.authUser.getValue()?.favorites ?? []
        return favorites.filter(favorites => favorites.group && favorites.group?._id)
    }

    function update() {
        const user: IUserData = state.authUser.getValue();
        setAuthUserData(user)
        setAuthUserPath(user?.path);
        setAuthUserAvatar(user?.avatar);
        setAuthUserNick(user?.nick);
        setAuthUserName(user?.name);
        setAuthUserMail(user?.email);
        setAuthUserRole(user?.role);
        setAuthUserCapiPassword(user?.capiPassword);
        setAuthUserDiscord(user?.discord);
        setAuthUserID(user?._id);
        setAuthUserExists(state.authUser.hasValue());
        setAuthUserIsAdmin(state.authUser.getValue()?.role == "admin")
        setAuthUserOnboardingCompleted(user?.onboardingCompleted ?? false);
        setAuthUserFavorites(getFavorites());
        setAuthUserPermissions(user?.permissions ?? []);
        setAuthUserSubscription(getSubscription());
        setAuthUserPaddleCustomerID(user?.paddleCustomerID ?? null);
        setAuthUserPaddleCheckoutState(user?.checkoutState ?? null);
        setAuthUserHasUnpaidSubscription(hasUnpaidSubscription());
        setAuthUserValidDriverLicense(getValidDriverLicense());
        setAuthUserUseSubscription(useSubscription());
        setAuthUserLanguage(language());
        setAuthUserSpeakerNick(user?.speakerNick);
        setAuthUserIsUpdating(!isUpdateCompleted())
        setAuthUserDiscountAlreadyUsed(user?.discountAlreadyUsed ?? false)
        setAuthUserNewsletter(user?.newsletter ?? null)
    }

    function isUpdateCompleted(): boolean {
        if (!state.authUserDataBeforeUpdate.getValue()) return true;
        return !ObjectUtils.hasChangedProperties(state.authUserDataBeforeUpdate.getValue(), state.authUser.getValue())
    }

    /* ----------------------------------------------------------------
 	 * RETURN
 	 * --------------------------------------------------------------*/

    return {
        authUserData,
        authUserPath,
        authUserAvatar,
        authUserNick,
        authUserName,
        authUserMail,
        authUserRole,
        authUserCapiPassword,
        authUserDiscord,
        authUserID,
        authUserIsAdmin,
        authUserExists,
        authUserOnboardingCompleted,
        authUserFavorites,
        authUserPermissions,
        authUserSubscription,
        authUserPaddleCustomerID,
        authUserPaddleCheckoutState,
        authUserHasUnpaidSubscription,
        authUserValidDriverLicense,
        authUserUseSubscription,
        authUserLanguage,
        authUserSpeakerNick,
        authUserIsUpdating,
        authUserDiscountAlreadyUsed,
        authUserNewsletter,
        setAuthUserIsUpdating,
        authUserHasPermission: (permission: UserPermissionType) => UserUtils.hasPermission(authUserData, permission)
    };
}

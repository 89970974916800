import {ISessionLeaderboardEntry} from "../types/ISessionLeaderboardEntry";
import {ISessionData, ISessionSetup} from "../models/ISessionData";
import {TimeUtils} from "./TimeUtils";
import {LeaderboardUtils} from "./LeaderboardUtils";
import {SharedConfig} from "../config/SharedConfig";
import {IEventData} from "../models/IEventData";
import {PathUtils} from "./PathUtils";
import {EventChildrenType} from "../types/EventChildrenType";

/******************************************************************
 * SessionUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class SessionUtils {

    static hasSessionStarted(leaderboard: ISessionLeaderboardEntry[]) {
        return leaderboard?.some(entry => !!entry.overall?.firstSignalTimestamp)
    }

    static hasSessionFullfilled(leaderboard: ISessionLeaderboardEntry[], session: ISessionData) {
        if (!leaderboard || !session) return false
        switch (session.setup.mode) {
            case "lap":
            case "race":
                switch (session.setup.finishType) {
                    case "duration":
                        switch (session.setup.timing) {
                            case "sync":
                                return SessionUtils.hasSessionFinishedByDuration(leaderboard, session)
                            case "async":
                                return false
                        }
                    case "laps":
                        switch (session.setup.timing) {
                            case "sync":
                                return SessionUtils.hasSessionFinishedByLaps(leaderboard, session)
                            case "async":
                                return false
                        }
                }
            case "gymkhana":
                return false
        }
    }

    static hasSessionFinishedByDuration(leaderboard: ISessionLeaderboardEntry[], session: ISessionData) {
        const earliestStartTimestamp = LeaderboardUtils.getEarliestSignalTimestamp(leaderboard)
        const now = new Date().toISOString()
        return TimeUtils.hasDurationBeenReached(
            earliestStartTimestamp,
            now,
            session.setup.duration
        )
    }

    static hasSessionFinishedByLaps(leaderboard: ISessionLeaderboardEntry[], session: ISessionData) {
        const maxDrivenLaps = LeaderboardUtils.getMaxOverallDrivenLaps(leaderboard)
        return maxDrivenLaps >= session.setup.laps
    }

    static createFollowSessionSetup(originalSession: ISessionData, event: IEventData): ISessionSetup {
        const setup = {...originalSession.setup} as ISessionSetup
        SessionUtils.updateSessionStartTime(setup, event, "follow")
        setup.activeFrom = null
        setup.activeUntil = null
        if (setup.startOrderFromSession) {
            setup.startOrderFromSession = originalSession._id.toString()
        }
        return setup
    }

    static updateSessionStartTime(setup: ISessionSetup, event: IEventData, type: "follow" | "reset") {
        if (!setup?.startTime) return
        let offset = SharedConfig.DEFAULT_EVENT_SESSION_START_TIME_DELAY
        if (type == "follow") {
            if (event.followSessionStartTimeDelay) {
                offset = event.followSessionStartTimeDelay
            }
        }
        if (type == "reset") {
            if (event.resetSessionStartTimeDelay) {
                offset = event.resetSessionStartTimeDelay
            }
        }
        const date = new Date(Date.now() + offset * 60 * 1000)
        if (date.getSeconds() > 0) {
            date.setMinutes(date.getMinutes() + 1);
        }
        date.setSeconds(0, 0);
        setup.startTime = date.toISOString()
    }

    static createFollowSessionName(originalSession: ISessionData): string {
        const name = originalSession.name
        const namePattern = /(\d+)$/;
        const match = name.match(namePattern);
        if (match) {
            const index = parseInt(match[1], 10) + 1;
            return name.replace(namePattern, index.toString());
        } else {
            return `${name} 2`;
        }
    }

    static createOriginalSessionUpdateData(
        originalSession: ISessionData,
        event: IEventData,
        eventChildren: EventChildrenType): ISessionData {
        const data: ISessionData = {}
        if (event.followSessionFinishOld) {
            data.state = "finished"
        }
        if (event.followSessionGameIDRule == "keep") {
            const existingPaths = [...eventChildren.sessions, ...eventChildren.results].map(item => item.path)
            data.path = PathUtils.getUniquePathFromPath(originalSession.path + existingPaths.length, existingPaths)
        }
        if (!data.state && !data.path) return null
        return data
    }

}

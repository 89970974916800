import {DriftTrackConditionType} from "../types/DriftTrackConditionType";
import {DriftTrackBundleType} from "../types/DriftTrackBundleType";

/******************************************************************
 * DriftUtils
 *
 * @author matthias.schulz@jash.de
 *****************************************************************/

export class DriftUtils {

    static mapTrackCondition(trackCondition: DriftTrackConditionType): string {
        switch (trackCondition) {
            case "drift_asphalt":
                return "driftAsphalt";
            case "drift_asphalt_wet":
                return "driftAsphaltWet";
            case "drift_dirt":
                return "driftDirt";
            case "drift_ice":
                return "driftIce";
        }
        return null
    }

    static mapTrackBundle(trackBundle: DriftTrackBundleType): string {
        switch (trackBundle) {
            case "none":
                return "none"
            case "rally_cross":
                return "rallyCross"
            case "rally":
                return "rally"
        }
        return null
    }

    static getTuningWithoutEngine(tuning: string): string {
        if (tuning.includes(" | ")) {
            return tuning.split(" | ")[1]
        }
        return tuning
    }

}
